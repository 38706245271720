import styled from 'styled-components';

export const OptionsContainer = styled.div`
  display: grid;
  align-content: start;
`;

export const ColumnContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-content: start;
`;

export const ColumnHeader = styled.h1`
  margin: 0;
  width: 100%;
  display: grid;
  place-content: start;
  align-content: center;
  padding: 0 20px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--dark-blue);
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--grey);
`;

export const SwitchWrapper = styled.div`
  width: 40px;
  height: 20px;
`;
