import {AnnotationsMap, makeAutoObservable} from 'mobx';
import {EditorController, UploadApi} from 'models';
import {ConfigApi} from 'models/api';
import {
  E_API_ERRORS,
  E_API_RESPONSES,
  E_REPEAT,
  E_ROTATION, E_UNITS,
  ROTATION_MAPPER,
  TConfigDoesNotExistDto,
  TEnvironment,
  TRotationAnimation,
  TRotationView,
  TUploadModelObject, TVisibleDimensions
} from 'shared';
import {TModelInternalAnimation, TProjectConfig, TProjectNode} from 'shared/types';
import {E_NODE_TYPES, PROJECT_CONFIG_INITIAL} from './project-config.constants';
import {getProjectId} from 'was-js-utils';

export class ProjectConfig {
  public editorController: EditorController | null = null;
  public configHasChanges = false;

  constructor(private _config: TProjectConfig = PROJECT_CONFIG_INITIAL) {
    if (!_config.id) {
      _config.id = Number(getProjectId());
    }

    makeAutoObservable(this, {editorController: false} as AnnotationsMap<this, never>);
  }

  injectEditorController = (editorController: EditorController): void => {
    this.editorController = editorController;

    this.editorController.model = this.modelNode.url;
    this.editorController.setAnimationSpeed(this.internalAnimation.speed);
    this.editorController.changeCameraOrbit(this.rotationView.theta, this.rotationView.phi, this.rotationView.R);
    this.editorController.toggleModelRotation(this.rotationAnimation.isActive);
    this.editorController.toggleZoom(this.scaleView);
    this.editorController.changeRotationSpeed(this.rotationAnimation.speed * ROTATION_MAPPER[this.rotationAnimation.side]);
    this.editorController.toggleAnimation(this.internalAnimation.isActive, this.internalAnimation.repeat);
    if (this.environment.isActive && this.environment?.url)
      this.editorController.changeEnvironment(this.environment.url);
    // this.editorController.changeDimensionsVisibility(this.visibleDimensions.isActive);
    // this.editorController.initializeDimensions(this.visibleDimensions.units);
    this.editorController.setCameraControls(this.gesturesRotation);
  };


  get config(): TProjectConfig {
    return this._config;
  }

  set config(config: TProjectConfig) {
    this._config = config;
  }

  getNode(type: E_NODE_TYPES): TProjectNode<E_NODE_TYPES> | never {
    const node = this.config.scene.nodes.find((node: TProjectNode<E_NODE_TYPES>): boolean => node.type === type);
    if (!node) {
      throw new Error(`Where is no node: ${type}`);
    }

    return node;
  }

  get modelNode(): TProjectNode<E_NODE_TYPES.MODEL> {
    return this.getNode(E_NODE_TYPES.MODEL) as TProjectNode<E_NODE_TYPES.MODEL>;
  }

  get modelNodeUrl(): string {
    return this.modelNode.url;
  }

  set modelNodeUrl(modelUrl: string) {
    this.modelNode.url = modelUrl;
    this.configHasChanges = true;
    if (this.editorController)
      this.editorController.model = modelUrl;
  }

  set modelUSDZNodeUrl(modelUrl: string) {
    this.configHasChanges = true;
    this.modelNode.urlUSDZ = modelUrl;
  }

  get internalAnimation(): TModelInternalAnimation {
    return this.modelNode?.options.internalAnimation;
  }

  get visibleDimensions(): TVisibleDimensions {
    return this.modelNode?.options.visibleDimensions;
  }

  setVisibleDimensionsIsActive = (value: boolean): void => {
    if (value !== this.visibleDimensions.isActive) {
      this.configHasChanges = true;
    }

    this.visibleDimensions.isActive = value;
    if (this.editorController) {
      this.editorController.changeDimensionsVisibility(value);
    }
  };

  setVisibleDimensionsUnits = (value: E_UNITS): void => {
    if (value !== this.visibleDimensions.units) {
      this.configHasChanges = true;
    }

    this.visibleDimensions.units = value;
    if (this.editorController) {
      this.editorController.changeDimensionsUnits(value);
    }
  };

  setInternalAnimationIsActive = (value: boolean): void => {
    if (value !== this.internalAnimation.isActive) {
      this.configHasChanges = true;
    }
    this.internalAnimation.isActive = value;
    if (this.editorController)
      this.editorController.toggleAnimation(value, this.internalAnimation.repeat);
  };
  setInternalAnimationSpeed = (speed: number): void => {
    this.configHasChanges = true;
    this.internalAnimation.speed = speed;
    if (this.editorController)
      this.editorController.setAnimationSpeed(speed);
  };

  setIntervalAnimationRepeat = (value: E_REPEAT): void => {
    this.configHasChanges = true;
    this.internalAnimation.repeat = value;
    if (this.editorController)
      this.editorController.toggleAnimation(true, value);
  };

  setIntervalAnimationOnStart = (value: boolean): void => {
    this.configHasChanges = true;
    this.internalAnimation.onStart = value;
  };

  setIntervalAnimationOnClick = (value: boolean): void => {
    this.configHasChanges = true;
    this.internalAnimation.onClick = value;
  };

  get cameraNode(): TProjectNode<E_NODE_TYPES.CAMERA> {
    return this.getNode(E_NODE_TYPES.CAMERA) as TProjectNode<E_NODE_TYPES.CAMERA>;
  }

  get scaleView(): boolean {
    return this.cameraNode.options.scaleView.isActive;
  }

  set scaleView(value: boolean) {
    this.configHasChanges = true;
    this.cameraNode.options.scaleView.isActive = value;
    if (this.editorController) {
      this.editorController.toggleZoom(value);
    }
  }

  get gesturesRotation(): boolean {
    return this.cameraNode.options?.gesturesRotation?.isActive ?? true;
  }

  set gesturesRotation(val: boolean) {
    this.configHasChanges = true;
    this.cameraNode.options.gesturesRotation.isActive = val;
    if (this.editorController) {
      this.editorController.setCameraControls(val);
      if (!val)
        this.editorController.changeCameraOrbit(this.rotationView.theta, this.rotationView.phi, this.rotationView.R);
    }
  }

  get rotationAnimation(): TRotationAnimation {
    return this.cameraNode.options.rotationAnimation;
  }

  setRotationAnimationIsActive = (value: boolean): void => {
    this.configHasChanges = true;
    this.rotationAnimation.isActive = value;
    if (this.editorController) {
      this.editorController.toggleModelRotation(value);
    }
  };

  setRotationAnimationSpeed = (value: number): void => {
    this.configHasChanges = true;
    this.rotationAnimation.speed = value;

    if (this.editorController)
      this.editorController.changeRotationSpeed(value * ROTATION_MAPPER[this.rotationAnimation.side]);
  };

  setRotationAnimationDirection = (value: E_ROTATION): void => {
    this.configHasChanges = true;
    this.rotationAnimation.side = value;

    if (this.editorController)
      this.editorController.changeRotationSpeed(this.rotationAnimation.speed * ROTATION_MAPPER[value]);
  };

  get lightNode(): TProjectNode<E_NODE_TYPES.LIGHT> {
    return this.getNode(E_NODE_TYPES.LIGHT) as TProjectNode<E_NODE_TYPES.LIGHT>;
  }

  get environment(): TEnvironment {
    return this.lightNode.options.environment;
  }

  setEnvironmentIsActive = (value: boolean): void => {
    this.configHasChanges = true;
    this.environment.isActive = value;

    if (this.editorController) {
      if (!value) {
        this.editorController.changeEnvironment('');
      } else if (this?.environment?.url) {
        this.editorController.changeEnvironment(this.environment.url);
      }
    }
  };

  setEnvironmentSrc = (value: string): void => {
    this.configHasChanges = true;
    this.environment.url = value;

    if (this.editorController) {
      this.editorController.changeEnvironment(value);
    }
  };

  get rotationView(): TRotationView {
    return this.cameraNode.options.rotationView;
  }

  setRotationViewTheta = (val: number): void => {
    this.configHasChanges = true;
    this.rotationView.theta = val;

    if (this.editorController) {
      this.editorController.changeCameraOrbit(val, this.rotationView.phi, this.rotationView.R);
    }
  };

  setRotationViewPhi = (val: number): void => {
    this.configHasChanges = true;
    this.rotationView.phi = val;

    if (this.editorController) {
      this.editorController.changeCameraOrbit(this.rotationView.theta, val, this.rotationView.R);
    }
  };

  setRotationViewR = (val: number): void => {
    this.configHasChanges = true;
    this.rotationView.R = val;

    if (this.editorController) {
      this.editorController.changeCameraOrbit(this.rotationView.theta, this.rotationView.phi, val);
    }
  };

  fetchConfig(): Promise<void> {
    const configApi = new ConfigApi();
    return configApi.get().then((res: TProjectConfig | TConfigDoesNotExistDto) => {
      if ((res as TConfigDoesNotExistDto)?.detail) {
        if ((res as TConfigDoesNotExistDto).detail !== E_API_RESPONSES.CONFIG_NOT_EXISTS) {
          throw new Error(E_API_ERRORS.UPLOAD_CONFIG_ERROR);
        }
      } else {
        if ((res as TProjectConfig).id) {
          this.config = (res as TProjectConfig);
        } else {
          throw new Error(E_API_ERRORS.UPLOAD_CONFIG_ERROR);
        }
      }
    });
  }

  uploadModel(file: File): Promise<void> {
    const uploadApi = new UploadApi();
    return uploadApi.uploadModel(file).then((res: TUploadModelObject) => {
      this.modelNodeUrl = res.modelUrl;
      this.modelUSDZNodeUrl = res.modelUSDZUrl;
    });
  }
}

export const projectConfig = new ProjectConfig();
