export const WEBSITE_ADDRESS = 'https://web-ar.studio';
export const WEBSITE_TEST_ADDRESS = 'https://test.web-ar.studio';

export const PLATFORM_ADDRESS = 'https://platform.web-ar.studio';
export const PLATFORM_TEST_ADDRESS = 'https://testplatform.web-ar.studio';

export const DOMAIN =
  process.env.NODE_ENV === 'development' ? '' : '.web-ar.studio';

export const PLATFORM_PATHS = {
  ACCOUNT_SETTINGS: '/my-settings',
  MY_PROJECTS: '/my_projects',
  PROJECT: '/project',
  PROJECT_SETTINGS: '/project-settings',
  PROJECT_TRIGGERS: '/project-triggers'
};

export const WEBSITE_PATHS = {
  BLOG: '/blog',
  PLANS: '/pricing',
  TECHNICAL_SUPPORT: '/technical-support'
};

// export const YOUTUBE_SRC = 'https://www.youtube.com/@web-ar.studio';

export const WIKI_EN_SRC = 'https://web-ar-studio.notion.site/Web-AR-Studio-Wiki-ac7725e42dd349ac8e4baa94e174b776';
export const WIKI_RU_SRC = 'https://web-ar-studio.notion.site/Web-AR-Studio-Wiki-ae5a2c430ad948fbaa9810dd32b1292b';
