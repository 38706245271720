import React from 'react';
import {TSwitcherOption} from './options-switcher.types';
import {OptionsSwitcherView} from './options-switcher.view';

type Props = {
  options: TSwitcherOption[];
  currentOptionId: any;
  changeOption: (option: any) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const OptionsSwitcher: React.FC<Props> = ({options, currentOptionId, changeOption}: Props) => {
  return (
    <OptionsSwitcherView options={options} currentOptionId={currentOptionId} changeOption={changeOption}/>
  );
};
