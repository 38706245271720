import {E_UNITS} from 'shared';

export const drawLine = (svgLine: any, dotHotspot1: any, dotHotspot2: any, dimensionHotspot?: any): void => {
  if (dotHotspot1 && dotHotspot1) {
    svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x);
    svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y);
    svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x);
    svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y);

    // use provided optional hotspot to tie visibility of this svg line to
    if (dimensionHotspot && !dimensionHotspot.facingCamera) {
      svgLine.classList.add('hide');
    } else {
      svgLine.classList.remove('hide');
    }
  }
};

export const renderSVG = (mvObject: any): void => {
  const lines = mvObject.querySelectorAll('line');

  drawLine(lines[0], mvObject.queryHotspot('hotspot-dot+X-Y+Z'), mvObject.queryHotspot('hotspot-dot+X-Y-Z'), mvObject.queryHotspot('hotspot-dim+X-Y'));
  drawLine(lines[1], mvObject.queryHotspot('hotspot-dot+X-Y-Z'), mvObject.queryHotspot('hotspot-dot+X+Y-Z'), mvObject.queryHotspot('hotspot-dim+X-Z'));
  drawLine(lines[2], mvObject.queryHotspot('hotspot-dot+X+Y-Z'), mvObject.queryHotspot('hotspot-dot-X+Y-Z')); // always visible
  drawLine(lines[3], mvObject.queryHotspot('hotspot-dot-X+Y-Z'), mvObject.queryHotspot('hotspot-dot-X-Y-Z'), mvObject.queryHotspot('hotspot-dim-X-Z'));
  drawLine(lines[4], mvObject.queryHotspot('hotspot-dot-X-Y-Z'), mvObject.queryHotspot('hotspot-dot-X-Y+Z'), mvObject.queryHotspot('hotspot-dim-X-Y'));
};

export const drawModelDimensionsHandler = (mvObject: any, units: E_UNITS = E_UNITS.MM): void => {
  let multiplier;
  switch (units) {
  case E_UNITS.CM:
    multiplier = 100;
    break;
  case E_UNITS.MM:
    multiplier = 1000;
    break;
  case E_UNITS.M:
  default:
    multiplier = 1;
  }

  const center = mvObject.getBoundingBoxCenter();
  const size = mvObject.getDimensions();
  const x2 = size.x / 2;
  const y2 = size.y / 2;
  const z2 = size.z / 2;

  mvObject.updateHotspot({
    name: 'hotspot-dot+X-Y+Z',
    position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`
  });

  mvObject.updateHotspot({
    name: 'hotspot-dim+X-Y',
    position: `${center.x + (x2 * 1.2)} ${center.y - (y2 * 1.1)} ${center.z}`
  });
  mvObject.querySelector('button[slot="hotspot-dim+X-Y"]').textContent =
      `${(size.z * multiplier).toFixed(2)} ${units}`;

  mvObject.updateHotspot({
    name: 'hotspot-dot+X-Y-Z',
    position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`
  });

  mvObject.updateHotspot({
    name: 'hotspot-dim+X-Z',
    position: `${center.x + (x2 * 1.2)} ${center.y} ${center.z - (z2 * 1.2)}`
  });
  mvObject.querySelector('button[slot="hotspot-dim+X-Z"]').textContent =
      `${(size.y * multiplier).toFixed(2)} ${units}`;

  mvObject.updateHotspot({
    name: 'hotspot-dot+X+Y-Z',
    position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`
  });

  mvObject.updateHotspot({
    name: 'hotspot-dim+Y-Z',
    position: `${center.x} ${center.y + (y2 * 1.1)} ${center.z - (z2 * 1.1)}`
  });
  mvObject.querySelector('button[slot="hotspot-dim+Y-Z"]').textContent =
      `${(size.x * multiplier).toFixed(2)} ${units}`;

  mvObject.updateHotspot({
    name: 'hotspot-dot-X+Y-Z',
    position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`
  });

  mvObject.updateHotspot({
    name: 'hotspot-dim-X-Z',
    position: `${center.x - (x2 * 1.2)} ${center.y} ${center.z - (z2 * 1.2)}`
  });
  mvObject.querySelector('button[slot="hotspot-dim-X-Z"]').textContent =
      `${(size.y * multiplier).toFixed(2)} ${units}`;

  mvObject.updateHotspot({
    name: 'hotspot-dot-X-Y-Z',
    position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`
  });

  mvObject.updateHotspot({
    name: 'hotspot-dim-X-Y',
    position: `${center.x - (x2 * 1.2)} ${center.y - (y2 * 1.1)} ${center.z}`
  });
  mvObject.querySelector('button[slot="hotspot-dim-X-Y"]').textContent =
      `${(size.z * multiplier).toFixed(2)} ${units}`;

  mvObject.updateHotspot({
    name: 'hotspot-dot-X-Y+Z',
    position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`
  });

  renderSVG(mvObject);
};

export const setDimensionsVisibility = (val: boolean): void => {
  const lines = document.getElementById('lines');
  const mv = document.getElementById('mv');
  let buttons;
  if (mv) {
    buttons = mv.querySelectorAll('button');
  }

  if (lines && buttons) {
    if (val) {
      lines.classList.remove('hide');
      buttons.forEach((btn) => btn.classList.remove('hide'));
    } else {
      lines.classList.add('hide');
      buttons.forEach((btn) => btn.classList.add('hide'));
    }
  }
};
