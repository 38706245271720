import React from 'react';
import {LoadingWindowView} from './loading-window.view';

type Props = {
  awaitText?: string;
};

/**
 * @return {React.FC<Props>}
 **/
export const LoadingWindow: React.FC<Props> = ({awaitText}: Props) => {
  return (
    <LoadingWindowView awaitText={awaitText}/>
  );
};
