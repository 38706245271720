import {observer} from 'mobx-react-lite';
import React from 'react';
import {AnimationBlockView} from './animation-block.view';
import {projectConfig} from 'models';

export const AnimationBlock: React.FC = observer(() => {
  const internalAnimation = projectConfig.internalAnimation;

  return (
    <AnimationBlockView
      isActive={internalAnimation.isActive}
      setIsActive={projectConfig.setInternalAnimationIsActive}
      speed={internalAnimation.speed}
      setSpeed={projectConfig.setInternalAnimationSpeed}
      repeatValue={internalAnimation.repeat}
      setRepeatValue={projectConfig.setIntervalAnimationRepeat}
      activateOnClick={internalAnimation.onClick}
      setActivateOnClick={projectConfig.setIntervalAnimationOnClick}
    />
  );
});
