import React from 'react';
import {E_REPEAT, OptionsContainer, t, CustomSelect, SwitchFieldBlock, UnfoldingBlock, SpeedBlock} from 'shared';
import styled from 'styled-components';
import {repeatSelectItems} from './animation-block.constants';

const SwitchFieldsWrapper = styled.div`
  display: grid;
  row-gap: 10px;
  padding-bottom: 10px;
`;

type Props = {
  isActive: boolean;
  setIsActive: (val: boolean) => void;
  speed: number;
  setSpeed: (val: number) => void;
  repeatValue: E_REPEAT;
  setRepeatValue: (val: E_REPEAT) => void;
  activateOnClick: boolean;
  setActivateOnClick: (val: boolean) => void;
};
export const AnimationBlockView: React.FC<Props> = ({
  isActive,
  setIsActive,
  speed,
  setSpeed,
  repeatValue,
  setRepeatValue,
  activateOnClick,
  setActivateOnClick
}: Props) => {
  return (
    <OptionsContainer>
      <UnfoldingBlock name={t('Animation')} initialOpened={isActive} changeOuterOpened={setIsActive}>
        <SwitchFieldsWrapper>
          <SwitchFieldBlock fieldName={t('Activate by click')} value={activateOnClick} setValue={setActivateOnClick}/>
        </SwitchFieldsWrapper>
        <CustomSelect header={t('Repeat')} selectArray={repeatSelectItems()} selectedValue={repeatValue}
          selectFn={(val: string): void => setRepeatValue(val as E_REPEAT)}/>
        <SpeedBlock speed={speed} setSpeed={setSpeed}/>
      </UnfoldingBlock>
    </OptionsContainer>
  );
};
