import React from 'react';
import {SliderOptionView} from './slider-option.view';

type Props = {
  value: number;
  changeValue: (val: number) => void;
  label?: string;
  min?: number;
  max?: number;
  step?: number;
  units?: string;
};

/**
 * @return {React.FC<Props>}
 **/
export const SliderOption: React.FC<Props> = ({
  value,
  changeValue,
  min,
  max,
  step,
  label,
  units,
}: Props) => {
  return (
    <SliderOptionView
      value={value}
      changeValue={changeValue}
      min={min}
      max={max}
      step={step}
      label={label}
      units={units}
    />
  );
};
