import React from 'react';
import {ColumnContainer, ColumnHeader, t} from 'shared';
import {AnimationBlock} from '../animation-block';
import {UploadModelBlock} from '../upload-model-block';

export const RightInterfaceView: React.FC = () => {
  return (
    <ColumnContainer>
      <ColumnHeader>{t('3D Model Settings')}</ColumnHeader>
      <UploadModelBlock />
      <AnimationBlock />
      {/*<Sizes />*/}
    </ColumnContainer>
  );
};
