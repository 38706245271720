import {styled} from '@mui/material';
import React from 'react';
import {default as MUSlider} from '@mui/material/Slider';
import {SLIDER_DEFAULTS} from './slider.constants';

const ColoredSlider = styled(MUSlider)(() => ({
  '& .MuiSlider-rail': {
    backgroundColor: 'var(--grey)',
    opacity: 0.5,
  },
  '& .MuiSlider-thumb': {
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0 0 0 5px rgba(53, 135, 170, 0.15)',
    },
  },
  color: 'var(--blue)',
}));

type Props = {
  value: number;
  onChange: (val: number) => void;
  min?: number;
  max?: number;
  step?: number;
};

/**
 * @return {React.FC<Props>}
 **/
export const Slider: React.FC<Props> = ({
  value,
  onChange,
  min = SLIDER_DEFAULTS.MIN,
  max = SLIDER_DEFAULTS.MAX,
  step = SLIDER_DEFAULTS.STEP
}: Props) => {
  const handleChange = (event: Event, newValue: number | number[]): void => {
    onChange(newValue as number);
  };

  return (
    <ColoredSlider value={value} onChange={handleChange} min={min} max={max} step={step}/>
  );
};
