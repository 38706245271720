export enum E_REPEAT {
  REPEAT = 'repeat',
  NO_REPEAT = 'no-repeat'
}

export const REPEAT_MAPPER: Record<E_REPEAT, number> = {
  [E_REPEAT.REPEAT]: Infinity,
  [E_REPEAT.NO_REPEAT]: 1
};

export enum E_ROTATION {
  CLOCKWISE = 'clockwise',
  COUNTERCLOCKWISE = 'counterclockwise'
}

export const ROTATION_MAPPER: Record<E_ROTATION, number> = {
  [E_ROTATION.CLOCKWISE]: -1,
  [E_ROTATION.COUNTERCLOCKWISE]: 1
};
