import React from 'react';
import styled from 'styled-components';
import {QUESTION_POPUP_OFFSET, questionCenterItems} from './support-button.constants';
import {SupportButton as SupportButtonWAS} from 'was-react-components/dist';

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
export const SupportButtonView: React.FC = () => {
  return (
    <Wrapper>
      <SupportButtonWAS items={questionCenterItems()} customOffset={QUESTION_POPUP_OFFSET}/>
    </Wrapper>
  );
};
