import {observer} from 'mobx-react-lite';
import React, {ReactNode, useState} from 'react';
import {ConfigApi, projectConfig, projectInfo} from 'models';
import {returnDateString, t} from 'shared';
import {ProjectPreview} from 'widgets';
import {EditorLayoutView} from './editor-layout.view';

type Props = {
  leftColumn: React.ReactNode;
  mainContent: React.ReactNode;
  rightColumn: ReactNode;
};

export const EditorLayout: React.FC<Props> = observer((props: Props) => {
  const projectName = projectInfo.projectName;
  const publishProjectText =
    `${t('Last save')}:\n ${returnDateString(new Date(projectInfo.lastUpdated))} UTC +0`;
  const isPublished = !projectConfig.configHasChanges;
  const publishProject = (): void => {
    if (!isPublished) {
      const configApi = new ConfigApi();
      configApi.set()
        .then(() => {
          projectConfig.configHasChanges = false;
        });
    }
  };

  const [previewQrModal, setPreviewQrModal] = useState<boolean>(false);
  const showPreviewProjectFn = (): void => setPreviewQrModal(true);
  const hidePreviewProjectFn = (): void => setPreviewQrModal(false);

  return (
    <>
      <EditorLayoutView {...props}
        publishProject={publishProject}
        publishProjectText={publishProjectText}
        projectName={projectName}
        isPublished={isPublished}
        showPreviewProjectFn={showPreviewProjectFn}
      />
      {previewQrModal && <ProjectPreview closeWindowFn={hidePreviewProjectFn}/>}
    </>
  );
});
