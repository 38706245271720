import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {EditorHeader} from 'was-react-components/dist';
import {SupportButton} from 'widgets';
import {t} from 'shared';
import {headerListBottomArray, headerListCenterArray} from './editor-layout.constants';

const EditorWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;

  grid-template-columns: 248px 1fr 248px;
  grid-template-rows: auto 1fr;
  align-content: start;
  background-color: var(--grey);

  & > header {
    grid-column-start: 1;
    grid-column-end: 4;
    position: relative;
    z-index: 2;
  }
`;

const LeftColWrapper = styled.aside`
  background-color: var(--light-grey);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  height: 100%;
  position: relative;
`;

const MainContentWrapper = styled.main`
`;

const RightColWrapper = styled(LeftColWrapper)``;

type Props = {
  leftColumn: React.ReactNode;
  mainContent: React.ReactNode;
  rightColumn: ReactNode;
  publishProject: () => void;
  publishProjectText: string;
  isPublished: boolean;
  projectName: string;
  showPreviewProjectFn: () => void;
};

export const EditorLayoutView: React.FC<Props> = ({
  leftColumn,
  mainContent,
  rightColumn,
  publishProject,
  publishProjectText,
  isPublished,
  projectName,
  showPreviewProjectFn,
}: Props) => {
  return (
    <EditorWrapper>
      <EditorHeader
        projectName={projectName}
        saveBtn={{
          isSaved: isPublished,
          onClick: publishProject,
          text: publishProjectText
        }}
        transparentBtn={{
          transparentBtnFn: showPreviewProjectFn,
          transparentBtnText: t('Viewing')
        }}
        logoList={{
          bottomItems: headerListBottomArray(),
          centerItems: headerListCenterArray()
        }}
      />
      <LeftColWrapper>{leftColumn}</LeftColWrapper>
      <MainContentWrapper>{mainContent}</MainContentWrapper>
      <RightColWrapper>{rightColumn}</RightColWrapper>
      <SupportButton/>
    </EditorWrapper>
  );
};
