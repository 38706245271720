import React from 'react';
import {DragAndDropView} from './drag-and-drop.view';
import {TButtonPropsField} from './drag-and-drop.types';

type Props = {
  uploadFileFn: (file: File) => void;
  onChangeFn?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  header?: string;
  description?: string;
  imageSrc?: string;
  button?: TButtonPropsField;
};

/**
 * @return {React.FC<Props>}
 **/
export const DragAndDrop: React.FC<Props> = ({
  header,
  description,
  button,
  imageSrc,
  uploadFileFn,
  onChangeFn
}: Props) => {
  const handleDrag = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOut = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    uploadFileFn(e.dataTransfer.files[0]);
  };

  return (
    <DragAndDropView
      header={header}
      button={button}
      imageSrc={imageSrc}
      handleDrop={handleDrop}
      handleDragIn={handleDragIn}
      handleDragOut={handleDragOut}
      handleDrag={handleDrag}
      description={description}
      onChangeFn={onChangeFn}
    />
  );
};
