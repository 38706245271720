import {TListItem} from 'was-react-components/dist';
import {getPlatformAddress, PLATFORM_PATHS, redirectTo, t} from 'shared';
import {getProjectId} from 'was-js-utils';

const leave = (): void => redirectTo(`${getPlatformAddress()}${PLATFORM_PATHS.MY_PROJECTS}`);

export const headerListCenterArray = (): TListItem[] =>  [
  {
    action: (): void => {
      const projectId = getProjectId();

      if (projectId) {
        redirectTo(`${getPlatformAddress()}${PLATFORM_PATHS.PROJECT_SETTINGS}?id=${projectId}`);
      } else {
        leave();
      }
    },
    name: t('Project Settings'),
  },
  {
    action: (): void => {
      const projectId = getProjectId();

      if (projectId) {
        redirectTo(`${getPlatformAddress()}${PLATFORM_PATHS.PROJECT_TRIGGERS}?id=${projectId}`);
      } else {
        leave();
      }
    },
    name: t('Export Qr Code'),
  },
  {
    action: (): void => redirectTo(`${getPlatformAddress()}${PLATFORM_PATHS.ACCOUNT_SETTINGS}`),
    name: t('Account Setup'),
  },
];

export const headerListBottomArray = (): TListItem[] => [
  {
    action: (): void => {
      const projectId = getProjectId();

      if (projectId) {
        redirectTo(`${getPlatformAddress()}${PLATFORM_PATHS.PROJECT}?id=${projectId}`);
      } else {
        leave();
      }
    },
    name: t('Exit'),
  },
];
