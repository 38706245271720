import {useLayoutEffect} from 'react';
import {authApi} from 'models';
import {getWebsiteAddress, redirectTo} from '../utils/';

export const useAuth = (deps: any[] = []): void => {
  useLayoutEffect(() => {
    authApi.verify().catch(() => {
      redirectTo( getWebsiteAddress());
    });
  }, deps);
};
