import {TListItem} from 'was-react-components/dist';
import {E_LANGUAGES, getWebsiteAddress, redirectTo, t, WEBSITE_PATHS, WIKI_EN_SRC, WIKI_RU_SRC} from 'shared';
import {getLanguage} from 'shared/utils/language';

export const questionCenterItems = (): TListItem[] => [
  {
    action: (): void => redirectTo(getLanguage() === E_LANGUAGES.RU ? WIKI_RU_SRC : WIKI_EN_SRC),
    name: 'Wiki',
  }, {
    action: (): void => redirectTo(`${getWebsiteAddress()}${WEBSITE_PATHS.TECHNICAL_SUPPORT}`),
    name: t('Technical support'),
  }, {
    action: (): void => redirectTo(`${getWebsiteAddress()}${WEBSITE_PATHS.BLOG}`),
    name: t('Blog'),
  },
];

const X_OFFSET = 3;
const Y_OFFSET = 0;
export const QUESTION_POPUP_OFFSET: [number, number] = [X_OFFSET, Y_OFFSET];
