import {E_API_ERRORS, TProjectConfig, TConfigDoesNotExistDto} from 'shared';
import {secureFetch, getProjectId} from 'was-js-utils';
import {projectConfig} from 'models';
import {BaseApi} from '../base-api';

export class ConfigApi extends BaseApi {
  constructor() {
    super();
  }

  get(): Promise<TProjectConfig | TConfigDoesNotExistDto> | never {
    const projectId = getProjectId();
    return secureFetch(`${this.endPoint}/api/v2/s3/configurator_config/?project_id=${projectId}`)
      .then((res: Response): Promise<TProjectConfig | TConfigDoesNotExistDto> => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(E_API_ERRORS.UPLOAD_CONFIG_ERROR);
        }
      });
  }

  set(): Promise<void> {
    const config = projectConfig.config;
    const headers = new Headers();
    headers.append('Content-type', 'application/json');

    return secureFetch(`${this.endPoint}/api/v2/s3/configurator_config/`, {
      body: JSON.stringify(config),
      headers: headers,
      method: 'post'
    })
      .then((res: Response) => {
        if (!res.ok) {
          throw new Error(E_API_ERRORS.UPDATE_CONFIG_ERROR);
        }
      });
  }
}

