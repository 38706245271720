import React, {DragEvent, useRef} from 'react';
import styled from 'styled-components';
import {ClassicButton} from 'was-react-components/dist';
import {IMAGES} from 'shared/constants/images';
import {TButtonPropsField} from './drag-and-drop.types';

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  justify-content: center;
  justify-items: center;
  padding: 36px 75px;
  background-color: white;
  row-gap: 34px;
  border-radius: 17px;
`;

const Header = styled.h1`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: var(--blue);
  margin: 0;
`;

const DragField = styled.div`
  position: relative;
  width: 695px;
  height: 369px;
  background-color: var(--blue);
  border-radius: 17px;
  background-image: url(${IMAGES.DASHED_BORDER});
  background-size: 665px 339px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: grid;
  place-content: center;

  transition: 500ms transform;
  
  &:hover {
    transform: scale(1.01);
    transition: 500ms transform;
  }
`;

const DragFieldText = styled.span`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: white;

  display: grid;
  justify-content: center;
  position: absolute;
  top: 54px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 356px;
`;

const Image = styled.img`
  max-height: 124px;
`;

const Button = styled(ClassicButton)`
  && {
    font-size: 18px;
    width: 223px;
    height: 38px;
  }
`;

const Input = styled.input`
  display: none;
`;

type Props = {
  handleDrop: (e: DragEvent) => void;
  handleDragIn: (e: DragEvent) => void;
  handleDragOut: (e: DragEvent) => void;
  handleDrag: (e: DragEvent) => void;
  header?: string;
  description?: string;
  button?: TButtonPropsField;
  imageSrc?: string;
  onChangeFn?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const DragAndDropView: React.FC<Props> = ({
  header,
  button,
  description,
  imageSrc,
  handleDrag,
  handleDragIn,
  handleDragOut,
  handleDrop,
  onChangeFn
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const inputClickHandler = (): void => {
    if (inputRef?.current) {
      inputRef?.current.click();
    }
  };

  return (
    <Wrapper>
      {header && <Header>{header}</Header>}
      <DragField onDrop={handleDrop} onDragEnter={handleDragIn} onDragLeave={handleDragOut} onDragOver={handleDrag}
        onClick={inputClickHandler}>
        <DragFieldText>
          {description}
        </DragFieldText>
        {imageSrc && <Image src={imageSrc} alt='image' draggable={false}/>}
      </DragField>
      {button?.buttonName && <Button onClick={inputClickHandler}>{button.buttonName}</Button>}
      <Input type={'file'} ref={inputRef} onClick={inputClickHandler} onChange={onChangeFn}/>
    </Wrapper>
  );
};
