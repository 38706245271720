import {E_OPTIONS_VARIANTS} from 'shared';
import {SVG_NAMES} from 'shared/components/other/create-svg/create-svg.constants';
import {t} from '../../shared/utils/language';

export const optionsArray = [
  {
    icon: SVG_NAMES.SCENE,
    id: E_OPTIONS_VARIANTS.SCENE,
  },
  {
    icon: SVG_NAMES.CAMERA,
    id: E_OPTIONS_VARIANTS.CAMERA,
  },
  {
    icon: SVG_NAMES.LIGHT,
    id: E_OPTIONS_VARIANTS.LIGHT,
  },
  {
    icon: SVG_NAMES.OTHER,
    id: E_OPTIONS_VARIANTS.OTHER,
  }
];

export const optionsHeaders = (): Record<E_OPTIONS_VARIANTS, string> => ({
  [E_OPTIONS_VARIANTS.SCENE]: t('Scene Settings'),
  [E_OPTIONS_VARIANTS.CAMERA]: t('Camera Settings'),
  [E_OPTIONS_VARIANTS.LIGHT]: t('Light Settings'),
  [E_OPTIONS_VARIANTS.OTHER]: 'Other'
});
