import {observer} from 'mobx-react-lite';
import React from 'react';
import {projectConfig} from 'models';
import {CameraOptionsView} from './camera-options.view';

export const CameraOptions: React.FC = observer(() => {
  const scaleView = projectConfig.scaleView;
  const gesturesRotation = projectConfig.gesturesRotation;
  const setScaleView = (val: boolean): void => {
    projectConfig.scaleView = val;
  };
  const setGesturesRotation = (val: boolean): void => {
    projectConfig.gesturesRotation = val;
  };

  return (
    <CameraOptionsView
      scaleView={scaleView}
      setScaleView={setScaleView}
      rotationActive={projectConfig.rotationAnimation.isActive}
      setRotationIsActive={projectConfig.setRotationAnimationIsActive}
      gesturesRotation={gesturesRotation}
      setGesturesRotation={setGesturesRotation}
    />
  );
});
