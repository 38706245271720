import {getCookie} from 'was-js-utils';
import {E_LANGUAGES, LANGUAGE_COOKIE} from '../constants';
import {DICT} from 'shared';

export const getLanguage = (): E_LANGUAGES | string | undefined => {
  return getCookie(LANGUAGE_COOKIE);
};

export const t = (str: string): string => {
  const language = (getLanguage() || E_LANGUAGES.EN) as E_LANGUAGES;

  return DICT[language] ? DICT[language][str] : DICT[E_LANGUAGES.EN][str];
};
