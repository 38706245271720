import React from 'react';
import {SVG_NAMES} from './create-svg.constants';
import {
  ArStudioIcon,
  AwaitSvg,
  Camera,
  CrossSvg,
  Cube,
  FalseSvg,
  Light,
  NoModelSvg,
  QuestionSvg,
  RightArrow,
  SaveSvg,
  Scene,
  TrueSvg,
  UploadImg
} from './create-svg.views';

type Props = {
  name: SVG_NAMES;
};

/**
 * @return {React.FC<Props>}
 **/
export const CreateSvg: React.FC<Props> = ({name}: Props) => {
  switch (name) {
  case SVG_NAMES.AR_STUDIO_ICON:
    return <ArStudioIcon />;
  case SVG_NAMES.RIGHT_ARROW:
    return <RightArrow />;
  case SVG_NAMES.SCENE:
    return <Scene />;
  case SVG_NAMES.LIGHT:
    return <Light />;
  case SVG_NAMES.CAMERA:
    return <Camera />;
  case SVG_NAMES.OTHER:
    return <Cube />;
  case SVG_NAMES.UPLOAD_IMAGE:
    return <UploadImg />;
  case SVG_NAMES.QUESTION:
    return <QuestionSvg />;
  case SVG_NAMES.NO_MODEL:
    return <NoModelSvg />;
  case SVG_NAMES.CROSS:
    return <CrossSvg />;
  case SVG_NAMES.AWAIT:
    return <AwaitSvg />;
  case SVG_NAMES.SAVE:
    return <SaveSvg />;
  case SVG_NAMES.TRUE:
    return <TrueSvg />;
  case SVG_NAMES.FALSE:
    return <FalseSvg />;
  default:
    return <></>;
  }
};
