import {getExtension, Http, secureFetch, cropExtension, generateFileName, getProjectId} from 'was-js-utils';
import {
  E_API_ERRORS,
  E_ERRORS,
  E_FILE_TYPES,
  E_HTTP_STATUSES,
  END_POINT,
  TConvertToUSDZDto,
  TGetEnvironmentResult,
  TUploadEnvironmentSuccess,
  TUploadModelObject
} from 'shared';
import {TPhotoItem} from 'widgets/upload-photos-lib/upload-photos-lib.types';
import {BaseApi} from '../base-api';

export class UploadApi extends BaseApi {
  constructor(endPoint: string = END_POINT) {
    super(endPoint);
  }

  uploadFile = (
    fileType: E_FILE_TYPES,
    file: File,
    filename: string | null = null
  ): Promise<string> | never => {
    const projectId = getProjectId();
    const fetchObj = new Http(this.endPoint);

    if (projectId) {
      let changedFileName;
      if (filename) {
        changedFileName = filename;
      } else {
        changedFileName = generateFileName(file.name);
      }

      file = new File([file], changedFileName, {type: file.type});
      return fetchObj.uploadFile(Number(projectId), fileType, file, secureFetch);
    } else {
      throw new Error(E_ERRORS.NO_PROJECT_ID);
    }
  };

  uploadModel = (file: File): Promise<TUploadModelObject> | never => {
    const projectId = getProjectId();
    const headers = new Headers();
    headers.append('Content-type', 'application/json');

    return this.uploadFile(E_FILE_TYPES.MODEL, file)
      .then((modelUrl: string) => {
        return secureFetch(`${this.endPoint}/api/v2/s3/glb_to_usdz/`, {
          body: JSON.stringify({
            project_id: Number(projectId),
            url: modelUrl
          }),
          headers,
          method: 'post'
        })
          .then((res: Response): Promise<TConvertToUSDZDto> | never => {
            if (res.ok) {
              return res.json();
            } else {
              throw new Error(E_API_ERRORS.FAILED_TO_CONVERT_MODEL);
            }
          })
          .then((res: TConvertToUSDZDto): TUploadModelObject => {
            return {
              modelUSDZUrl: res.url,
              modelUrl: modelUrl
            };
          });
      })
      .catch((): never => {
        throw new Error(E_API_ERRORS.FAILED_TO_UPLOAD_FILE);
      });
  };

  uploadEnvironment = (file: File): Promise<TUploadEnvironmentSuccess> | never => {
    const projectId = getProjectId();
    const formData = new FormData();
    formData.set('project_id', String(projectId));
    const fileNameExtension = getExtension(file.name);
    const croppedFileName = cropExtension(file.name);

    file = new File([file], `${croppedFileName}__${Date.now()}.${fileNameExtension}`, {type: file.type});
    formData.set('file', file);

    return secureFetch(`${this.endPoint}/api/v1/image-proc/configurator/panorama/`, {
      body: formData,
      method: 'post'
    })
      .then((res: Response): Promise<TUploadEnvironmentSuccess> => {
        if (res.ok) {
          return res.json();
        } else if (res.status === E_HTTP_STATUSES.PAYLOAD_TOO_LARGE) {
          throw new Error(E_API_ERRORS.OBJECT_TOO_LARGE);
        } else {
          throw new Error(E_API_ERRORS.SOMETHING_WENT_WRONG);
        }
      });
  };

  getEnvironment = (): Promise<TPhotoItem[]> | never => {
    const projectId = getProjectId();

    return secureFetch(`${this.endPoint}/api/v2/s3/panoramas/?project_id=${projectId}`)
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(E_API_ERRORS.SOMETHING_WENT_WRONG);
        }
      })
      .then((res: TGetEnvironmentResult): TPhotoItem[] => {
        return res.map((elem: [string, string]): TPhotoItem => {
          return {
            iconSrc: elem[0],
            src: elem[1],
          };
        });
      });
  };
}
