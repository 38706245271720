import React from 'react';
import {OptionsContainer, UnfoldingBlock, t} from 'shared';
import {InitialView} from '../initial-view';
import {RotationBlock} from '../rotation-block';

type Props = {
  scaleView: boolean;
  setScaleView: (val: boolean) => void;
  rotationActive: boolean;
  setRotationIsActive: (val: boolean) => void;
  gesturesRotation: boolean;
  setGesturesRotation: (val: boolean) => void;
};

export const CameraOptionsView: React.FC<Props> = ({
  scaleView,
  setScaleView,
  rotationActive,
  setRotationIsActive,
  gesturesRotation,
  setGesturesRotation
}: Props) => {
  return (
    <OptionsContainer>
      <UnfoldingBlock name={t('Starting view')} hideSwitch initialOpened>
        <InitialView/>
      </UnfoldingBlock>
      <UnfoldingBlock name={t('Scaling')} initialOpened={scaleView} changeOuterOpened={setScaleView}/>
      <UnfoldingBlock name={t('Rotation')} initialOpened={gesturesRotation} changeOuterOpened={setGesturesRotation}/>
      <UnfoldingBlock name={t('Auto-rotation')} initialOpened={rotationActive} changeOuterOpened={setRotationIsActive}>
        <RotationBlock/>
      </UnfoldingBlock>
    </OptionsContainer>
  );
};
