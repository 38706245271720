import {observer} from 'mobx-react-lite';
import React from 'react';
import {projectConfig} from 'models';
import {SizesView} from './sizes.view';

/**
 * @return {React.FC<Props>}
 **/
export const Sizes: React.FC = observer(() => {
  return (
    <SizesView
      sizesIsActive={projectConfig.visibleDimensions.isActive}
      setSizesIsActive={projectConfig.setVisibleDimensionsIsActive}
      sizesUnit={projectConfig.visibleDimensions.units}
      changeSizes={projectConfig.setVisibleDimensionsUnits}/>
  );
});
