import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {projectConfig, UploadApi} from 'models';
import {
  E_API_ERRORS,
  END_POINT_IMAGE_SERVICE,
  environmentExtensions,
  getWebsiteAddress,
  redirectTo,
  t,
  TUploadEnvironmentSuccess,
  WEBSITE_PATHS
} from 'shared';
import {verifyFileExtension} from 'was-js-utils';
import {TPhotoItem} from '../upload-photos-lib/upload-photos-lib.types';
import {envUploadErrorModalInitial} from './light-options.constants';
import {TEnvUploadErrorModal} from './light-options.types';
import {LightOptionsView} from './light-options.view';

export const LightOptions: React.FC = observer(() => {
  const environment = projectConfig.environment;
  const [uploadEnvDAD, setUploadEnvDAD] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [photoItems, setPhotoItems] = useState<TPhotoItem[]>([]);
  const [envModalError, setEnvModalError] = useState<TEnvUploadErrorModal>(envUploadErrorModalInitial);

  useEffect(() => {
    const uploadApi = new UploadApi();
    uploadApi.getEnvironment()
      .then((res: TPhotoItem[]) => {
        setPhotoItems(res);
      })
      .catch(() => null);
  }, []);

  const closeEnvModal = (): void => setEnvModalError(envUploadErrorModalInitial);
  const uploadFileFn = (file: File | null | undefined): void => {
    if (file && verifyFileExtension(environmentExtensions, file)) {
      setShowLoading(true);
      setUploadEnvDAD(false);

      const uploadApi = new UploadApi(END_POINT_IMAGE_SERVICE);
      uploadApi.uploadEnvironment(file)
        .then((res: TUploadEnvironmentSuccess) => {
          setShowLoading(false);
          setPhotoItems([...photoItems, {
            iconSrc: res.url_icon,
            src: res.url
          }]);
        })
        .catch((err: Error) => {
          setShowLoading(false);
          if (err.message === E_API_ERRORS.OBJECT_TOO_LARGE) {
            setEnvModalError({
              btnActionFn: () => redirectTo(`${getWebsiteAddress()}${WEBSITE_PATHS.PLANS}`),
              btnText: t('Change plan'),
              header: t('Upload error'),
              status: true,
              text: t('The file size is large')
            });
          } else {
            setEnvModalError({
              btnActionFn: () => closeEnvModal(),
              btnText: t('Close'),
              header: t('Failed to upload file'),
              status: true,
              text: t('The file size is large')
            });
          }
        });
    }
  };

  const onChangeFileFn = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.hasOwnProperty('target') ? e.target?.files?.[0] : null;

    uploadFileFn(file);
  };

  return (
    <LightOptionsView
      selectedPhotoId={environment.url}
      changeSelectedPhoto={projectConfig.setEnvironmentSrc}
      environmentIsActive={environment.isActive}
      setEnvironmentIsActive={projectConfig.setEnvironmentIsActive}
      uploadEnvDAD={uploadEnvDAD}
      changeUploadEnvDAD={setUploadEnvDAD}
      showLoading={showLoading}
      uploadFileFn={uploadFileFn}
      onChangeFileFn={onChangeFileFn}
      photoItems={photoItems}
      envModalError={envModalError}
      closeEnvModal={closeEnvModal}
    />
  );
});
