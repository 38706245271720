import {isAppTest} from 'was-js-utils';
import {PLATFORM_ADDRESS, PLATFORM_TEST_ADDRESS, WEBSITE_ADDRESS, WEBSITE_TEST_ADDRESS} from '../constants';

export const redirectTo = (url: string): void => {
  document.location.href = url;
};

export const getWebsiteAddress = (): string => {
  if (isAppTest()) {
    return WEBSITE_TEST_ADDRESS;
  } else {
    return WEBSITE_ADDRESS;
  }
};

export const getPlatformAddress = (): string => {
  if (isAppTest()) {
    return PLATFORM_TEST_ADDRESS;
  } else {
    return PLATFORM_ADDRESS;
  }
};
