import {observer} from 'mobx-react-lite';
import React from 'react';
import {projectConfig} from 'models';
import {RotationBlockView} from './rotation-block.view';

export const RotationBlock: React.FC = observer(() => {
  const rotationAnimation = projectConfig.rotationAnimation;

  return (
    <RotationBlockView direction={rotationAnimation.side}
      setDirection={projectConfig.setRotationAnimationDirection}
      speed={rotationAnimation.speed}
      setSpeed={projectConfig.setRotationAnimationSpeed}/>
  );
});
