import React from 'react';
import styled from 'styled-components';
import {E_ROTATION, CustomSelect} from 'shared';
import {t} from '../../shared/utils/language';
import {SpeedBlock} from '../../shared/components/other/speed-block';
import {directionSelectItems} from './rotation-block.constants';

const Wrapper = styled.div`
  display: grid;
`;

type Props = {
  direction: string;
  setDirection: (direction: E_ROTATION) => void;
  speed: number;
  setSpeed: (speed: number) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const RotationBlockView: React.FC<Props> = ({direction, setDirection, speed, setSpeed}: Props) => {
  return (
    <Wrapper>
      <CustomSelect header={t('Direction')} selectArray={directionSelectItems()} selectedValue={direction}
        selectFn={(direction: string): void => setDirection(direction as E_ROTATION)}/>
      <SpeedBlock speed={speed} setSpeed={setSpeed}/>
    </Wrapper>
  );
};
