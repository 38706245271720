import React from 'react';
import styled from 'styled-components';
import {CreateSvg, SVG_NAMES} from '../../other/create-svg';

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(53, 135, 170, 0.5);
  
  display: grid;
  place-content: center;
  place-items: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  background-color: white;
  border-radius: 17px;
`;

const CloseBtn = styled.div`
  width: 46px;
  height: 46px;
  display: grid;
  place-content: center;
  position: absolute;
  top: -23px;
  right: -23px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  background-color: white;
  cursor: pointer;
`;

type Props = {
  children: React.ReactNode;
  closeModalFn?: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const ModalWindowView: React.FC<Props> = ({children, closeModalFn}: Props) => {
  return (
    <Wrapper>
      <ContentWrapper>
        {closeModalFn && <CloseBtn onClick={closeModalFn}>
          <CreateSvg name={SVG_NAMES.CROSS}/>
        </CloseBtn>}
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};
