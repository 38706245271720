import {E_REPEAT, E_ROTATION, E_UNITS} from 'shared/constants';
import {TProjectConfig, TProjectNode} from 'shared/types';

const CONFIG_VERSION = '1.0.1';

export enum E_NODE_TYPES {
  MODEL = 'Object3D',
  CAMERA = 'Camera',
  LIGHT = 'CommonLight'
}

export const PROJECT_CONFIG_INITIAL: TProjectConfig = {
  id: null,
  scene: {
    id: 1,
    nodes: [{
      id: 1,
      options: {
        internalAnimation: {
          isActive: false,
          onClick: false,
          onStart: true,
          repeat: E_REPEAT.REPEAT,
          speed: 1,
        },
        visibleDimensions: {
          isActive: false,
          units: E_UNITS.MM
        }
      },
      type: E_NODE_TYPES.MODEL,
      url: '',
      urlUSDZ: ''
    } as TProjectNode<E_NODE_TYPES.MODEL>,
    {
      id: 2,
      options: {
        gesturesRotation: {
          isActive: true
        },
        rotationAnimation: {
          isActive: false,
          side: E_ROTATION.CLOCKWISE,
          speed: 1
        },
        rotationView: {
          R: 100,
          phi: 90,
          theta: 0
        },
        scaleView: {
          isActive: true
        }
      },
      type: E_NODE_TYPES.CAMERA
    } as TProjectNode<E_NODE_TYPES.CAMERA>,
    {
      id: 3,
      options: {
        environment: {
          isActive: false
        },
      },
      type: E_NODE_TYPES.LIGHT
    } as TProjectNode<E_NODE_TYPES.LIGHT>
    ],
  },
  version: CONFIG_VERSION
};
