import {makeAutoObservable} from 'mobx';

export class PreloaderController {
  constructor(public status: boolean = false) {
    makeAutoObservable(this);
  }

  show(): void {
    this.status = true;
  }

  close(): void {
    this.status = false;
  }

  *closeAsync(p: Promise<any>): Generator<Promise<any>, void> {
    yield p;
    this.close();
  }
}

export const preloader = new PreloaderController();
