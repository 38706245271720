import {E_API_ERRORS, E_HTTP_STATUSES, getPlatformAddress, redirectTo} from 'shared';
import {LOCAL_API_ADDRESS} from 'shared/constants';
import {getJwtCookie, parseJwt} from 'was-js-utils';
import {BaseApi} from '../base-api';

export class AuthApi extends BaseApi {
  constructor() {
    super(LOCAL_API_ADDRESS);
  }

  verify = (): Promise<void> => {
    return fetch(`${LOCAL_API_ADDRESS}/verify`, {
      credentials: 'include',
      method: 'POST',
    })
      .then((data: Response): void => {
        if (data.status !== E_HTTP_STATUSES.OK) {
          throw new Error(E_API_ERRORS.UNAUTHORIZED);
        }
      });
  };

  logOut = (): void => {
    const accessToken = getJwtCookie();
    const userId = parseJwt(accessToken)?.userId;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (userId) {
      fetch(`${LOCAL_API_ADDRESS}/exit`, {
        body: JSON.stringify({
          userId: userId,
        }),
        credentials: 'include',
        headers: headers,
        method: 'DELETE',
      })
        .then((): void => {
          redirectTo(getPlatformAddress());
        });
    }
  };
}

export const authApi = new AuthApi();
