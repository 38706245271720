export enum E_ERRORS {
  NO_PROJECT_ID = 'No project id !'
}

export const modelsExtensions = ['.glb'];

export const environmentExtensions = ['.jpg', '.jpeg', '.png', '.hdr'];

export enum E_UNITS {
  MM = 'mm',
  CM = 'cm',
  M = 'm'
}
