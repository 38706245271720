import {useLayoutEffect, useState} from 'react';
import {preloader, projectConfig, projectInfo} from 'models';
import {getWebsiteAddress, redirectTo} from '../utils';

export const usePreloader = (): boolean => {
  const [startRendering, setStartRendering] = useState<boolean>(false);
  useLayoutEffect(() => {
    preloader.show();

    const p1 = projectConfig.fetchConfig()
      .then(() => {
        setStartRendering(true);
        // return projectConfig.initialActionsPromise;
      });
    const p2 = new Promise((res) => {
      // const TIMEOUT = 2000;
      const TIMEOUT = 1;
      setTimeout(() => res(''), TIMEOUT);
    });

    const p3 = projectInfo.fetchConfig();

    const promiseAll = Promise.all([p1, p2, p3])
      .catch((): void => {
        if (process.env.NODE_ENV !== 'development')
          redirectTo(getWebsiteAddress());
      });

    preloader.closeAsync(promiseAll);
  }, []);

  return startRendering;
};
