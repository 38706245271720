import React from 'react';
import {ErrorModalView} from './error-modal.view';

type Props = {
  header: string;
  text: string;
  btnText: string;
  btnActionFn: () => void;
  closeModalFn: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const ErrorModal: React.FC<Props> = ({
  header, text, btnText, btnActionFn, closeModalFn
}: Props) => {
  return (
    <ErrorModalView header={header} text={text} btnText={btnText} btnActionFn={btnActionFn}
      closeModalFn={closeModalFn}/>
  );
};
