import {styled, Tooltip, tooltipClasses, TooltipProps} from '@mui/material';
import React, {ReactElement} from 'react';

type Props = {
  text: string;
  children: ReactElement<any, any>;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow: theme.shadows[3],
    color: '#14192D',
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.4,
    maxWidth: 260,
    textAlign: 'center',
    whiteSpace: 'pre',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

/**
 * @return {React.FC<Props>}
 **/
export const CommonTooltipView: React.FC<Props> = ({text, children}: Props) => {
  return (
    <LightTooltip title={text} arrow>
      {children}
    </LightTooltip>
  );
};
