import {makeAutoObservable} from 'mobx';
import {OtherApi} from 'models';

export class ProjectInfo {
  public qrCodeSrc = '';
  public projectName = '';
  public lastUpdated = '';

  constructor() {
    makeAutoObservable(this);
  }

  fetchConfig = async(): Promise<void> => {
    const otherApi = new OtherApi();
    const cfg = await otherApi.getProjectInfo();
    this.qrCodeSrc = cfg.qrcode_link;
    this.projectName = cfg.project_name;
    this.lastUpdated = cfg.date_updated;
  };
}

export const projectInfo = new ProjectInfo();
