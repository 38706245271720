import {E_REPEAT, E_UNITS, REPEAT_MAPPER} from 'shared';
import {drawModelDimensionsHandler, renderSVG, setDimensionsVisibility} from './model-viewer.utils';

export class ModelViewerController {
  constructor(public mvObject: any) {
    mvObject.autoRotateDelay = 0;

    mvObject.addEventListener('camera-change', () => renderSVG( mvObject));
  }

  get model(): string {
    return this.mvObject.src;
  }

  set model(url: string) {
    this.mvObject.src = url;
  }

  set environment(url: string) {
    this.mvObject['skybox-image'] = url;
  }

  setAnimationPlay = (val: boolean, repeat: E_REPEAT): void => {
    if (val) {
      const timerId = setInterval(() => {
        this.mvObject.play({
          repetitions: REPEAT_MAPPER[repeat]
        });
        this.mvObject.onplay = (): void => {
          clearInterval(timerId);
        };
      }, 500);
    } else {
      this.mvObject.pause();
    }
  };

  setAnimationSpeed = (val: number): void => {
    this.mvObject.timeScale = val;
  };

  toggleZoom = (val: boolean): void => {
    this.mvObject.disableZoom = !val;
  };

  toggleModelRotation = (val: boolean): void => {
    this.mvObject.autoRotate = val;
  };

  changeRotationSpeed = (multiplier: number): void => {
    const INITIAL_DENOMINATOR = 32;
    const DEFAULT_ROTATION = Math.PI / INITIAL_DENOMINATOR;

    this.mvObject.rotationPerSecond = `${DEFAULT_ROTATION * multiplier}rad`;
  };

  changeCameraOrbit = (theta: number, phi: number, R: number): void => {
    this.mvObject.cameraOrbit = `${theta}deg ${phi}deg ${R}%`;
  };

  changeEnvironment = (val: string): void => {
    if (val) {
      this.mvObject.skyboxImage = val;
    } else {
      this.mvObject.skyboxImage = null;
    }
  };

  changeDimensionsVisibility = (val: boolean): void => {
    setDimensionsVisibility(val);
  };

  changeDimensionsUnits = (val: E_UNITS): void => {
    drawModelDimensionsHandler(this.mvObject, val);
  };

  initializeDimensions = (val: E_UNITS): void => {
    this.mvObject.addEventListener('load', () => drawModelDimensionsHandler(this.mvObject, val));
  };

  setCameraControls = (val: boolean): void => {
    this.mvObject.cameraControls = val;
  };
}
