import React from 'react';
import {ErrorModal, IMAGES, ModalWindow, OptionsContainer, t, UnfoldingBlock, DragAndDrop, LoadingWindow} from 'shared';
import {UploadPhotosLib} from '../upload-photos-lib';
import {TPhotoItem} from '../upload-photos-lib/upload-photos-lib.types';
import {TEnvUploadErrorModal} from './light-options.types';


type Props = {
  selectedPhotoId: string | null | undefined;
  changeSelectedPhoto: (photo: string) => void;
  environmentIsActive: boolean;
  setEnvironmentIsActive: (val: boolean) => void;
  uploadEnvDAD: boolean;
  changeUploadEnvDAD: (val: boolean) => void;
  showLoading: boolean;
  uploadFileFn: (file: File) => void;
  onChangeFileFn: (e: React.ChangeEvent<HTMLInputElement>) => void;
  photoItems: TPhotoItem[];
  envModalError: TEnvUploadErrorModal;
  closeEnvModal: () => void;
};

export const LightOptionsView: React.FC<Props> = ({
  selectedPhotoId,
  changeSelectedPhoto,
  environmentIsActive,
  setEnvironmentIsActive,
  uploadEnvDAD,
  changeUploadEnvDAD,
  showLoading,
  uploadFileFn,
  onChangeFileFn,
  photoItems,
  envModalError,
  closeEnvModal
}: Props) => {
  return (
    <>
      <OptionsContainer>
        <UnfoldingBlock name={t('Environment')} initialOpened={environmentIsActive}
          changeOuterOpened={setEnvironmentIsActive}>
          <UploadPhotosLib photosArray={photoItems}
            selectedPhotoId={selectedPhotoId}
            changeSelectedPhoto={changeSelectedPhoto}
            uploadFn={(): void => changeUploadEnvDAD(true)}
          />
        </UnfoldingBlock>
      </OptionsContainer>
      {
        uploadEnvDAD &&
        <ModalWindow closeModalFn={(): void => changeUploadEnvDAD(false)}>
          <DragAndDrop
            header={t('Download the environment')}
            button={{buttonName: t('Upload a map')}}
            imageSrc={IMAGES.UPLOAD_ENV}
            uploadFileFn={uploadFileFn}
            onChangeFn={onChangeFileFn}
            description={t('Move your map')}
          />
        </ModalWindow>
      }
      {showLoading && <LoadingWindow awaitText={`${t('Please wait')}...`}/>}
      {envModalError.status &&
        <ErrorModal text={envModalError.text} btnText={envModalError.btnText} header={envModalError.header}
          closeModalFn={closeEnvModal}
          btnActionFn={envModalError.btnActionFn}/>}
    </>
  );
};
