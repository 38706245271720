import React from 'react';
import styled, {css, FlattenSimpleInterpolation} from 'styled-components';
import {CreateSvg} from '../../shared';
import {TSwitcherOption} from './options-switcher.types';

const OptionsWrapper = styled.nav`
  width: 100%;
  display: grid;
  grid-auto-rows: 34px;
  border-top: 1px solid var(--grey);
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
`;

type TOption = {
  selected: boolean;
};

const Option = styled.div<TOption>`
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  cursor: pointer;

  ${({selected}): FlattenSimpleInterpolation | null => (selected ? css`
    & > svg {
      fill: var(--blue)
    }` : null)}
`;

type Props = {
  options: TSwitcherOption[];
  currentOptionId: any;
  changeOption: (option: any) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const OptionsSwitcherView: React.FC<Props> = ({options, currentOptionId, changeOption}: Props) => {
  const contentJsx = options.map((option: TSwitcherOption) =>
    <Option key={option.id} selected={option.id === currentOptionId}
      onClick={(): void => changeOption(option.id)}><CreateSvg name={option.icon}/></Option>
  );

  return (
    <OptionsWrapper>
      {contentJsx}
    </OptionsWrapper>
  );
};
