import React from 'react';
import styled from 'styled-components';
import {ModalLayout, ClassicButton} from 'was-react-components/dist';


const ModalWrapper = styled.div`
  padding: 29px 45px;
  display: grid;
  justify-content: center;
  row-gap: 30px;
`;

const Span = styled.span`
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: var(--blue);
  max-width: 380px;
  text-align: center;
`;

const GradientButton = styled(ClassicButton)`
  height: 46px;
  width: auto;
`;

type Props = {
  header: string;
  text: string;
  btnText: string;
  btnActionFn: () => void;
  closeModalFn: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const ErrorModalView: React.FC<Props> = ({header, text, btnText, btnActionFn, closeModalFn}: Props) => {
  return (
    <ModalLayout closeFn={closeModalFn} headerString={header} >
      <ModalWrapper>
        <Span>{text}</Span>
        <GradientButton onClick={btnActionFn}>{btnText}</GradientButton>
      </ModalWrapper>
    </ModalLayout>
  );
};
