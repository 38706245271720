import React from 'react';
import {SpeedBlockView} from './speed-block.view';

type Props = {
  speed: number;
  setSpeed: (val: number) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const SpeedBlock: React.FC<Props> = ({speed, setSpeed}: Props) => {
  return (
    <SpeedBlockView speed={speed} setSpeed={setSpeed}/>
  );
};
