import React from 'react';
import {CreateSvg, ModalWindow, SVG_NAMES} from 'shared';
import styled, {keyframes} from 'styled-components';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  width: 548px;
  height: 281px;
  display: grid;
  place-content: center;
  justify-items: center;
  row-gap: 30px;
  
  & > *:first-child {
    animation-name: ${rotation};
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;

const Span = styled.span`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: var(--blue);
`;

type Props = {
  awaitText?: string;
};

export const LoadingWindowView: React.FC<Props> = ({awaitText}: Props) => {
  return (
    <ModalWindow>
      <Wrapper>
        <CreateSvg name={SVG_NAMES.AWAIT}/>
        {awaitText && <Span>{awaitText}</Span>}
      </Wrapper>
    </ModalWindow>
  );
};
