import {useLayoutEffect} from 'react';
import {getWebsiteAddress, redirectTo} from '../utils';
import {getProjectId} from 'was-js-utils';

export const useCheckProjectId = (deps: any[] = []): void => {
  useLayoutEffect(() => {
    const projectId = getProjectId();
    if (!projectId) {
      redirectTo( getWebsiteAddress());
    }
  }, deps);
};
