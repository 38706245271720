import React from 'react';
import {SwitchView} from './switch.view';

type Props = {
  value: boolean;
  changeValue: () => void;
};
export const Switch: React.FC<Props> = ({value, changeValue}: Props) => {
  return (
    <SwitchView state={value} changeState={(): void => changeValue()}/>
  );
};
