import React from 'react';
import {preloader} from 'models';
import {PreloaderView} from './preloader.view';
import { observer } from 'mobx-react-lite';

export const Preloader: React.FC = observer(() => {
  return (
    preloader.status ? <PreloaderView />: null
  );
});
