import React, {useEffect, useState} from 'react';
import {UnfoldingBlockView} from './unfolding-block.view';

type Props = {
  children?: React.ReactNode;
  name: string;
  initialOpened?: boolean;
  changeOuterOpened?: (val: boolean) => void;
  hideSwitch?: boolean;
};

/**
 * @return {React.FC<Props>}
 **/
export const UnfoldingBlock: React.FC<Props> = ({
  children,
  name,
  initialOpened,
  changeOuterOpened,
  hideSwitch = false
}: Props) => {
  const [opened, setOpened] = useState<boolean>(initialOpened ?? false);

  useEffect(() => {
    if (changeOuterOpened) {
      changeOuterOpened(opened);
    }
  }, [opened]);
  const changeOpened = (): void => setOpened(!opened);

  return (
    <UnfoldingBlockView name={name} opened={opened} setOpened={changeOpened} hideSwitch={hideSwitch}>{children}</UnfoldingBlockView>
  );
};
