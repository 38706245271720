import React, {useState} from 'react';
import {E_OPTIONS_VARIANTS} from '../../shared';
import {optionsArray} from './left-interface.constants';
import {LeftInterfaceView} from './left-interface.view';

export const LeftInterface: React.FC = () => {
  const [currentOptionsId, setCurrentOptionsId] = useState<E_OPTIONS_VARIANTS>(E_OPTIONS_VARIANTS.CAMERA);

  return (
    <LeftInterfaceView currentOptionsId={currentOptionsId} switcherOptions={optionsArray}
      changeOption={setCurrentOptionsId}/>
  );
};
