import React from 'react';
import styled from 'styled-components';
import {Switch, SwitchWrapper} from 'shared';

const Wrapper = styled.div`
  border-bottom: 1px solid var(--grey);
`;

const Section = styled.section`
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;
  align-content: center;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
`;

type TH2Props = {
  opened: boolean;
};

const H2 = styled.h2<TH2Props>`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: ${({opened}): string => (opened ? 'var(--blue)' : 'var(--dark-grey)')};
  margin: 0;
  transition: 300ms color;
`;

const ContentWrapper = styled.div`
  display: grid;
  padding: 5px 20px 25px 20px;
  box-sizing: border-box;
`;


type Props = {
  children?: React.ReactNode;
  name: string;
  opened: boolean;
  setOpened: () => void;
  hideSwitch: boolean;
};

/**
 * @return {React.FC<Props>}
 **/
export const UnfoldingBlockView: React.FC<Props> = ({children, name, opened, setOpened, hideSwitch}: Props) => {
  return (
    <Wrapper>
      <Section>
        <H2 opened={opened}>{name}</H2>
        {!hideSwitch && <SwitchWrapper>
          <Switch value={opened} changeValue={setOpened}/>
        </SwitchWrapper>}
      </Section>
      {opened && children &&
        <ContentWrapper>
          {children}
        </ContentWrapper>}
    </Wrapper>
  );
};
