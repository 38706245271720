import React from 'react';
import {CustomSelect, E_UNITS, t, UnfoldingBlock} from 'shared';
import {unitsSelectItems} from './sizes.utils';

type Props = {
  sizesIsActive: boolean;
  setSizesIsActive: (val: boolean) => void;
  sizesUnit: E_UNITS | '';
  changeSizes: (val: E_UNITS) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const SizesView: React.FC<Props> = ({sizesIsActive, setSizesIsActive, sizesUnit, changeSizes}: Props) => {
  return (
    <UnfoldingBlock name={t('Sizes')} changeOuterOpened={setSizesIsActive} initialOpened={sizesIsActive}>
      <CustomSelect
        header={t('Units of measurement')}
        selectArray={unitsSelectItems()}
        selectedValue={sizesUnit}
        selectFn={(unit: string): void => changeSizes(unit as E_UNITS)}/>
    </UnfoldingBlock>
  );
};
