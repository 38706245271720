import {E_REPEAT, E_UNITS, TModelViewer} from 'shared';
import {ModelViewerController} from '../model-viewer';

export class EditorController {
  public modelViewer: ModelViewerController;

  constructor(modelViewerObject: TModelViewer) {
    this.modelViewer = new ModelViewerController(modelViewerObject);
  }

  set model(url: string) {
    this.modelViewer.model = url;
  }

  toggleAnimation = (val: boolean, repeat: E_REPEAT): void => {
    this.modelViewer.setAnimationPlay(val, repeat);
  };

  setAnimationSpeed = (val: number): void => {
    this.modelViewer.setAnimationSpeed(val);
  };

  toggleZoom = (val: boolean): void => {
    this.modelViewer.toggleZoom(val);
  };

  toggleModelRotation = (val: boolean): void => {
    this.modelViewer.toggleModelRotation(val);
  };

  changeRotationSpeed = (multiplier: number): void => {
    this.modelViewer.changeRotationSpeed(multiplier);
  };

  changeCameraOrbit = (theta: number, phi: number, R: number): void => {
    this.modelViewer.changeCameraOrbit(theta, phi, R);
  };

  changeEnvironment = (val: string): void => {
    this.modelViewer.changeEnvironment(val);
  };

  changeDimensionsVisibility = (val: boolean): void => {
    this.modelViewer.changeDimensionsVisibility(val);
  };

  changeDimensionsUnits = (val: E_UNITS): void => {
    this.modelViewer.changeDimensionsUnits(val);
  };

  initializeDimensions = (val: E_UNITS): void => {
    this.modelViewer.initializeDimensions(val);
  };

  setCameraControls = (val: boolean): void => {
    this.modelViewer.setCameraControls(val);
  };
}
