import React, {ForwardedRef} from 'react';
import styled from 'styled-components';
import {TModelViewer} from 'shared/types';
import {CreateSvg, t} from 'shared';
import {SVG_NAMES} from 'shared/components/other/create-svg';
import {CircleLoader} from 'shared/components/ui/loaders/circle-loader';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > model-viewer {
    z-index: 1;
    width: 100%;
    height: 100%;
  }
`;

const NoModelWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  row-gap: 45px;
  place-content: center;
  place-items: center;
  z-index: 0;
`;

const Span = styled.span`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;

  text-align: center;

  color: var(--dark-grey);
`;

const ProgressWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  background-color: var(--grey);
  z-index: 100;
`;

type Props = {
  noModel: boolean;
  loadingProgress: number;
  loaded: boolean;
};

export const CanvasWrapperView: React.ForwardRefExoticComponent<TModelViewer> = React.forwardRef<TModelViewer, any>(
  ({noModel, loadingProgress, loaded}: Props, ref: ForwardedRef<TModelViewer>) => {
    return (
      <Wrapper>
        <model-viewer
          ref={ref}
          alt='Model viewer'
          id={'mv'}
          shadow-intensity='1'
          touch-action='pan-y'
          crossorigin='Anonymous'
          min-camera-orbit={'-360deg 0 100%'}
          max-camera-orbit={'360deg 180 400%'}
          interaction-prompt='none'
          disable-tap
        >
          {!loaded && <ProgressWrapper slot='progress-bar'>
            <CircleLoader progress={loadingProgress}/>
          </ProgressWrapper>}
          {/*<ModelDimensions />*/}
        </model-viewer>
        {
          noModel && <NoModelWrapper>
            <CreateSvg name={SVG_NAMES.NO_MODEL}/>
            <Span>{t('The 3D model is not loaded')}</Span>
          </NoModelWrapper>
        }
      </Wrapper>
    );
  });
