import React from 'react';
import {CircleLoaderView} from './circle-loader.view';

type Props = {
  progress: number;
};

/**
 * @return {React.FC<Props>}
 **/
export const CircleLoader: React.FC<Props> = ({
  progress = 0
}: Props) => {
  return (
    <CircleLoaderView progress={progress}/>
  );
};
