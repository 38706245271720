import React from 'react';
import styled, {css, FlattenSimpleInterpolation} from 'styled-components';
import {Slider} from 'shared';

const OptionWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;
  align-content: center;
  align-items: center;
  width: 100%;
`;

type TSliderWrapperProps = {
  label: string | undefined;
};

const SliderWrapper = styled.div<TSliderWrapperProps>`
  display: grid;
  grid-column-end: 3;

  ${({label}: TSliderWrapperProps): FlattenSimpleInterpolation => (label ? css`
    grid-column-start: 2;
  ` : css`
    grid-column-start: 1;
  `)}
`;

const LabelWrapper = styled.span`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: var(--dark-grey);
  width: 15px;

  grid-column-start: 1;
  grid-column-end: 2;
`;

const ValueWrapper = styled(LabelWrapper)`
  width: 35px;
  display: grid;
  justify-content: end;

  grid-column-start: 3;
  grid-column-end: 4;
`;

type Props = {
  value: number;
  changeValue: (val: number) => void;
  label?: string;
  min?: number;
  max?: number;
  step?: number;
  units?: string;
};

/**
 * @return {React.FC<Props>}
 **/
export const SliderOptionView: React.FC<Props> = ({
  value,
  changeValue,
  min,
  max,
  step,
  label,
  units
}: Props) => {
  return (
    <OptionWrapper>
      {label && <LabelWrapper>{label} </LabelWrapper>}
      <SliderWrapper label={label}>
        <Slider value={value} onChange={changeValue} min={min} max={max} step={step}/>
      </SliderWrapper>
      {<ValueWrapper>{`${value}${units || ''}`}</ValueWrapper>}
    </OptionWrapper>
  );
};
