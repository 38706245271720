import React from 'react';
import styled from 'styled-components';
import {Switch, SwitchWrapper} from 'shared';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`;

const Span = styled.span`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: var(--dark-grey);
`;

type Props = {
  fieldName: string;
  value: boolean;
  setValue: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const SwitchFieldBlockView: React.FC<Props> = ({fieldName, value, setValue}: Props) => {
  return (
    <Wrapper>
      <Span>{fieldName}</Span>
      <SwitchWrapper>
        <Switch value={value} changeValue={setValue}/>
      </SwitchWrapper>
    </Wrapper>
  );
};
