export const INITIAL_VIEW_DEFAULTS = {
  MAX_PHI: 180,
  MAX_R: 400,
  MAX_THETA: 360,
  MIN_PHI: 0,
  MIN_R: 100,
  MIN_THETA: -360,
  R: 100,
  STEP: 1,
  phi: 0,
  theta: 0
};
