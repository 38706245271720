import React from 'react';
import styled from 'styled-components';
import {ClassicButton} from 'was-react-components/dist';
import {IMAGES, ModalWindow, t, DragAndDrop, LoadingWindow} from 'shared';

const Wrapper = styled.div`
  display: grid;
  place-content: center;
  grid-auto-columns: 208px;
  grid-template-rows: 37px;

  border-bottom: 1px solid var(--grey);
  padding: 24px 0;
`;

const Button = styled(ClassicButton)`
  && {
    font-size: 10px;
  }
`;

const Input = styled.input`
  display: none;
`;

type Props = {
  onChangeFn: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploadFileFn: (file: File) => void;
  showDragAndDrop: boolean;
  setShowDragAndDrop: (bool: boolean) => void;
  showLoading: boolean;
  buttonClick: () => void;
  buttonClickText: string;
};

export const UploadModelBlockView: React.FC<Props> = ({
  onChangeFn,
  uploadFileFn,
  showDragAndDrop,
  setShowDragAndDrop,
  showLoading,
  buttonClick,
  buttonClickText
}: Props) => {
  return (
    <Wrapper>
      <Button onClick={buttonClick}>{buttonClickText}</Button>
      <Input type={'file'} />
      {showDragAndDrop && <ModalWindow closeModalFn={(): void => setShowDragAndDrop(false)}>
        <DragAndDrop header={t('Uploading a 3D model')} button={{buttonName: t('Upload a model')}}
          imageSrc={IMAGES.UPLOAD_MODEL} uploadFileFn={uploadFileFn} onChangeFn={onChangeFn}
          description={t('Move your model in the format')}
        />
      </ModalWindow>}
      {showLoading && <LoadingWindow awaitText={`${t('Please wait')}...`}/>}
    </Wrapper>
  );
};
