import React from 'react';
import {SwitchFieldBlockView} from './switch-field-block.view';

type Props = {
  fieldName: string;
  value: boolean;
  setValue: (val: boolean) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const SwitchFieldBlock: React.FC<Props> = ({fieldName, value, setValue}: Props) => {
  return (
    <SwitchFieldBlockView fieldName={fieldName} value={value} setValue={(): void => setValue(!value)}/>
  );
};
