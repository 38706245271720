import {observer} from 'mobx-react-lite';
import React from 'react';
import {projectConfig} from '../../models';
import {InitialViewView} from './initial-view.view';

export const InitialView: React.FC = observer(() => {
  const rotationView = projectConfig.rotationView;

  return (
    <InitialViewView theta={rotationView.theta} phi={rotationView.phi} R={rotationView.R}
      setTheta={projectConfig.setRotationViewTheta} setPhi={projectConfig.setRotationViewPhi}
      setR={projectConfig.setRotationViewR}/>
  );
});
