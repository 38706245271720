import React from 'react';
import {ModalWindowView} from './modal-window.view';

type Props = {
  children: React.ReactNode;
  closeModalFn?: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const ModalWindow: React.FC<Props> = ({children, closeModalFn}: Props) => {
  return (
    <ModalWindowView closeModalFn={closeModalFn}>{children}</ModalWindowView>
  );
};
