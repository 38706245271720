export enum SVG_NAMES {
  AR_STUDIO_ICON,
  RIGHT_ARROW,
  SCENE,
  CAMERA,
  LIGHT,
  OTHER,
  UPLOAD_IMAGE,
  QUESTION,
  NO_MODEL,
  CROSS,
  AWAIT,
  SAVE,
  TRUE,
  FALSE
}
