import React from 'react';
import styled from 'styled-components';
import {SliderOption, t} from 'shared/index';
import {SPEED_DEFAULTS} from './speed-block.constants';

const Wrapper = styled.div`
  display: grid;
`;

const H3 = styled.h3`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: var(--dark-grey);
  margin: 0;
  margin-top: 10px;
`;

type Props = {
  speed: number;
  setSpeed: (val: number) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const SpeedBlockView: React.FC<Props> = ({speed, setSpeed}: Props) => {
  return (
    <Wrapper>
      <H3>{t('Speed')}</H3>
      <SliderOption value={speed} changeValue={setSpeed} min={SPEED_DEFAULTS.START} max={SPEED_DEFAULTS.END}
        step={SPEED_DEFAULTS.STEP} units={'x'}/>
    </Wrapper>
  );
};
