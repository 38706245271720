import React from 'react';

export const ArStudioIcon: React.FC = () =>
  <svg width='27' height='23' viewBox='0 0 27 23' xmlns='http://www.w3.org/2000/svg' fill='#3587AA'>
    <path
      d='M0 14.8916H4.53393L10.6682 5.01869L16.799 14.8916H21.3879L12.9621 1.34024C11.0448 -1.08244 9.129 0.330788 8.41077 1.34024L0 14.8916Z'
    />
    <path
      d='M26.9933 19.8891C26.9933 20.8889 26.7464 21.6573 26.2525 22.1944C25.7586 22.7315 25.0507 23 24.1288 23C23.2069 23 22.499 22.7315 22.0051 22.1944C21.5112 21.6573 21.2643 20.8861 21.2643 19.8809C21.2643 18.8756 21.5112 18.1085 22.0051 17.5797C22.5017 17.0481 23.2124 16.7824 24.137 16.7824C25.0617 16.7824 25.7682 17.0495 26.2566 17.5838C26.7478 18.1182 26.9933 18.8866 26.9933 19.8891ZM22.6019 19.8891C22.6019 20.5639 22.7295 21.0721 22.9846 21.4136C23.2398 21.7551 23.6212 21.9259 24.1288 21.9259C25.1468 21.9259 25.6557 21.2469 25.6557 19.8891C25.6557 18.5285 25.1495 17.8482 24.137 17.8482C23.6294 17.8482 23.2467 18.0204 22.9888 18.3647C22.7308 18.7062 22.6019 19.2143 22.6019 19.8891Z'
    />
    <path d='M18.8405 22.9871V16.7699H20.1579V22.9871H18.8405Z'/>
    <path
      d='M16.8448 22.1706C17.4284 21.6263 17.7203 20.8424 17.7203 19.819C17.7203 18.8579 17.4398 18.1095 16.8788 17.5737C16.3178 17.0378 15.5302 16.7699 14.5159 16.7699H13.8826V17.8501H14.5839C15.7625 17.8501 16.3518 18.5177 16.3518 19.853C16.3518 21.2166 15.7172 21.8984 14.4479 21.8984H13.8826V22.9871H14.3246C15.4239 22.9871 16.264 22.7149 16.8448 22.1706Z'
    />
    <path d='M6.23624 22.9995H4.91878V17.8795H4.11657V16.7824H9.03498V17.8795H6.23624V22.9995Z'/>
    <path
      d='M12.8127 16.7699V20.7388C12.8127 21.1919 12.7093 21.5891 12.5025 21.9303C12.2985 22.2715 12.0024 22.5331 11.6143 22.7149C11.2261 22.8967 10.7671 22.9876 10.2373 22.9876C9.43834 22.9876 8.81786 22.7862 8.37587 22.3834C7.93389 21.9779 7.71289 21.4241 7.71289 20.722V18.6872H9.02611V20.5248C9.02611 20.9975 9.12244 21.3444 9.3151 21.5653C9.50776 21.7863 9.8265 21.8968 10.2713 21.8968C10.702 21.8968 11.0136 21.7863 11.2063 21.5653C11.4018 21.3416 11.4995 20.9919 11.4995 20.5165V16.7699H12.8127Z'
    />
    <path
      d='M0.000715526 22.636C0.50014 22.8787 1.07448 23 1.72373 23C1.75738 23 1.79067 22.9996 1.82361 22.9989V21.941C1.58778 21.941 1.32696 21.9037 1.04118 21.8293C0.758176 21.7548 0.411353 21.6266 0.000715526 21.4446V22.636Z'
    />
    <path
      d='M2.63102 21.2998C2.63102 21.1812 2.59773 21.0764 2.53114 20.9854C2.46455 20.8916 2.36605 20.802 2.23565 20.7165C2.10802 20.631 1.84582 20.4945 1.44905 20.3069C1.07726 20.1332 0.798414 19.9663 0.612517 19.8064C0.42662 19.6464 0.27818 19.4603 0.167197 19.2479C0.0562136 19.0356 0.000721988 18.7873 0.000721988 18.5033C0.000721988 17.9683 0.182457 17.5477 0.545927 17.2416C0.912171 16.9354 1.41714 16.7824 2.06085 16.7824L2.84958 16.782V17.8497L2.06085 17.85C2.04696 17.8498 2.03309 17.8497 2.01923 17.8497C1.77507 17.8497 1.58778 17.9062 1.45738 18.0193C1.32697 18.1323 1.26177 18.2799 1.26177 18.4619C1.26177 18.575 1.28813 18.6743 1.34084 18.7598C1.39356 18.8425 1.4768 18.9239 1.59056 19.0038C1.70709 19.0811 1.98038 19.2217 2.41044 19.4258C2.97923 19.6961 3.36906 19.9677 3.57993 20.2407C3.7908 20.511 3.89623 20.8433 3.89623 21.2377C3.89623 21.2586 3.89594 21.2793 3.89536 21.2998H2.63102Z'
    />
    <path
      d='M21.7103 0H13.4716C15.1576 3.29815 16.6939 3.82054 17.3082 3.82054H21.5688C22.9777 3.81018 23.2436 4.9738 23.2005 5.55691C23.014 6.70758 22.035 7.10458 21.5688 7.15925H17.7686L22.3774 14.8904H26.9948L23.9406 10.1348C26.4116 9.18119 27.0261 6.42842 26.9992 5.17659C26.8674 1.11525 23.4184 0.0333044 21.7103 0Z'
    />
  </svg>;
export const RightArrow: React.FC = () =>
  <svg width='8' height='5' viewBox='0 0 8 5'
    xmlns='http://www.w3.org/2000/svg' fill='#3587AA'>
    <path d='M7.05333 0L4 3.09042L0.94 0L0 0.951417L4 5L8 0.951417L7.05333 0Z'/>
  </svg>;

export const Scene: React.FC = () => <svg width='14' height='16' viewBox='0 0 14 16' fill='#B4B7BB'
  xmlns='http://www.w3.org/2000/svg'>
  <path
    d='M0 11.6388L1.55949 10.7511L4.57848 3.825C4.62923 3.70861 4.74039 3.62898 4.86824 3.61747C4.9961 3.60618 5.12 3.66449 5.19122 3.76989L6.65002 5.92971V0L3.54792e-05 3.79266V9.77083L0 9.7758V11.6388Z'/>
  <path d='M4.96663 4.68292L2.57305 10.1743L6.65002 7.85368V7.17532L4.96663 4.68292Z'/>
  <path fillRule='evenodd' clipRule='evenodd'
    d='M6.99991 16L13.6486 12.236L6.99991 8.45209L0.352064 12.236L6.99991 16ZM9.44998 12.2527C9.44998 11.4649 8.39672 10.8709 6.99998 10.8709C5.60328 10.8709 4.54999 11.4649 4.54999 12.2527C4.54999 13.0405 5.60324 13.6346 6.99998 13.6346C8.39672 13.6346 9.44998 13.0405 9.44998 12.2527Z'/>
  <path
    d='M6.99998 12.9437C8.06825 12.9437 8.74998 12.5344 8.74998 12.2527C8.74998 11.971 8.06825 11.5618 6.99998 11.5618C5.93172 11.5618 5.24999 11.971 5.24999 12.2527C5.24999 12.5344 5.93172 12.9437 6.99998 12.9437Z'/>
  <path d='M7.35002 7.85382L11.075 9.97656L9.92251 7.41622H7.35002V7.85382Z'/>
  <path fillRule='evenodd' clipRule='evenodd'
    d='M7.35002 0.000380082V6.72528H10.15C10.2883 6.72528 10.4137 6.80571 10.4699 6.93046L12.1052 10.5636L14 11.6385V3.79256L7.35002 0.000380082ZM9.8 4.72158C9.8 5.48354 10.428 6.10344 11.2 6.10344C11.972 6.10344 12.6 5.48354 12.6 4.72158C12.6 3.95962 11.972 3.33971 11.2 3.33971C10.428 3.33971 9.8 3.95962 9.8 4.72158Z'/>
  <path
    d='M11.2 4.03065C10.814 4.03065 10.5 4.3406 10.5 4.72158C10.5 5.10256 10.814 5.41251 11.2 5.41251C11.586 5.41251 11.9 5.10256 11.9 4.72158C11.9 4.3406 11.586 4.03065 11.2 4.03065Z'/>
</svg>;

export const Camera: React.FC = () => <svg width='20' height='14' viewBox='0 0 20 14' fill='#B4B7BB'
  xmlns='http://www.w3.org/2000/svg'>
  <path fillRule='evenodd' clipRule='evenodd'
    d='M2.77357 1.27273C1.94803 1.27273 1.27991 1.93709 1.27991 2.758V11.242C1.27991 12.0629 1.94803 12.7273 2.77357 12.7273H11.3055C12.131 12.7273 12.7991 12.0629 12.7991 11.242V2.758C12.7991 1.93709 12.131 1.27273 11.3055 1.27273H2.77357ZM0 2.758C0 1.23455 1.24152 0 2.77357 0H11.3055C12.8375 0 14.0791 1.23455 14.0791 2.758V11.242C14.0791 12.7655 12.8375 14 11.3055 14H2.77357C2.03798 14 1.33251 13.7094 0.812361 13.1922C0.292215 12.675 0 11.9735 0 11.242V2.758Z'/>
  <path fillRule='evenodd' clipRule='evenodd'
    d='M13.7604 4.00894L17.3032 1.96621C17.3641 1.92973 17.4335 1.90974 17.5045 1.90822C17.5756 1.90671 17.6458 1.92373 17.7082 1.95758C17.7706 1.99143 17.823 2.04094 17.8602 2.10118C17.8973 2.16142 17.918 2.23029 17.9201 2.30094V11.6988C17.9201 11.9889 17.5873 12.1989 17.3032 12.0348L13.7617 9.99203L13.1191 11.0929L16.6607 13.1357C17.7601 13.7695 19.2 13.0148 19.2 11.6988V2.30094C19.2 0.986213 17.7601 0.231485 16.6607 0.865304L13.1204 2.90803L13.7629 4.00894H13.7604Z'/>
</svg>;

export const Light: React.FC = () => <svg width='17' height='16' viewBox='0 0 17 16' fill='#B4B7BB'
  xmlns='http://www.w3.org/2000/svg'>
  <path
    d='M8.71059 0.510638C8.71059 0.228621 8.48197 0 8.19995 0C7.91793 0 7.68931 0.228621 7.68931 0.510638V1.87234C7.68931 2.15436 7.91793 2.38298 8.19995 2.38298C8.48197 2.38298 8.71059 2.15436 8.71059 1.87234V0.510638Z'
  />
  <path
    d='M3.26525 2.34315C3.06583 2.14373 2.74251 2.14373 2.5431 2.34315C2.34368 2.54256 2.34368 2.86588 2.5431 3.0653L3.50597 4.02817C3.70538 4.22758 4.0287 4.22758 4.22812 4.02817C4.42753 3.82875 4.42753 3.50543 4.22812 3.30601L3.26525 2.34315Z'
  />
  <path
    d='M13.8568 3.0653C14.0562 2.86588 14.0562 2.54256 13.8568 2.34315C13.6574 2.14373 13.3341 2.14373 13.1347 2.34315L12.1718 3.30601C11.9724 3.50543 11.9724 3.82875 12.1718 4.02817C12.3712 4.22758 12.6945 4.22758 12.8939 4.02817L13.8568 3.0653Z'
  />
  <path
    d='M8.19995 3.40426C5.66179 3.40426 3.60421 5.46184 3.60421 8C3.60421 10.5382 5.66179 12.5957 8.19995 12.5957C10.7381 12.5957 12.7957 10.5382 12.7957 8C12.7957 5.46184 10.7381 3.40426 8.19995 3.40426Z'
  />
  <path
    d='M0.71059 7.48936C0.428572 7.48936 0.199951 7.71798 0.199951 8C0.199951 8.28202 0.428572 8.51064 0.71059 8.51064H2.07229C2.35431 8.51064 2.58293 8.28202 2.58293 8C2.58293 7.71798 2.35431 7.48936 2.07229 7.48936H0.71059Z'
  />
  <path
    d='M14.3276 7.48936C14.0456 7.48936 13.817 7.71798 13.817 8C13.817 8.28202 14.0456 8.51064 14.3276 8.51064H15.6893C15.9713 8.51064 16.2 8.28202 16.2 8C16.2 7.71798 15.9713 7.48936 15.6893 7.48936H14.3276Z'
  />
  <path
    d='M4.22812 12.694C4.42753 12.4946 4.42753 12.1713 4.22812 11.9718C4.0287 11.7724 3.70538 11.7724 3.50597 11.9718L2.5431 12.9347C2.34368 13.1341 2.34368 13.4574 2.5431 13.6569C2.74251 13.8563 3.06583 13.8563 3.26525 13.6569L4.22812 12.694Z'
  />
  <path
    d='M12.8939 11.9718C12.6945 11.7724 12.3712 11.7724 12.1718 11.9718C11.9724 12.1713 11.9724 12.4946 12.1718 12.694L13.1347 13.6569C13.3341 13.8563 13.6574 13.8563 13.8568 13.6569C14.0562 13.4574 14.0562 13.1341 13.8568 12.9347L12.8939 11.9718Z'
  />
  <path
    d='M8.71059 14.1277C8.71059 13.8456 8.48197 13.617 8.19995 13.617C7.91793 13.617 7.68931 13.8456 7.68931 14.1277V15.4894C7.68931 15.7714 7.91793 16 8.19995 16C8.48197 16 8.71059 15.7714 8.71059 15.4894V14.1277Z'
  />
</svg>;

export const Cube: React.FC = () => <svg width='15' height='17' viewBox='0 0 15 17' fill='#B4B7BB'
  xmlns='http://www.w3.org/2000/svg'>
  <path
    d='M14.4499 12.5484C14.4959 12.5212 14.534 12.482 14.5604 12.4348C14.5868 12.3876 14.6004 12.3341 14.5999 12.2798V4.87324L7.93245 8.67244L7.99245 16.2632L14.4499 12.5484Z'
  />
  <path fillRule='evenodd' clipRule='evenodd'
    d='M7.84995 0.644221C7.80369 0.619347 7.75221 0.60635 7.69995 0.60635C7.64769 0.60635 7.59621 0.619347 7.54995 0.644221L1.10748 4.35133L7.63245 8.14286L14.2849 4.35133L7.84995 0.644221ZM0.949984 12.5484L7.39245 16.2555L7.33245 8.67244L0.799984 4.88091V12.2798C0.799508 12.3341 0.813146 12.3876 0.839499 12.4348C0.865853 12.482 0.90398 12.5212 0.949984 12.5484Z'
  />
</svg>;

export const UploadImg: React.FC = () => <svg width='12' height='12' viewBox='0 0 12 12' fill='none'
  xmlns='http://www.w3.org/2000/svg'>
  <path
    d='M10.8107 4.13637V2.65259H9.27195V4.13637H8.08289L9.06206 5.1735L10.0414 6.21063L11.0206 5.1735L12 4.13637H10.8107Z'
    fill='#3587AA'/>
  <path d='M4.57493 3.95081L2.97778 6.75961H7.4939L6.47509 4.364L5.45607 5.3828L4.57493 3.95081Z' fill='#3587AA'/>
  <path d='M9.63337 8.11824H0.751159V1.70111H9.63337V2.14903H10.3845V0.949951H0V11.05H10.3845V6.7229H9.63337V8.11824Z'
    fill='#3587AA'/>
</svg>;

export const QuestionSvg: React.FC = () => <svg width='9' height='15' viewBox='0 0 9 15' fill='none'
  xmlns='http://www.w3.org/2000/svg'>
  <path
    d='M4.35887 10.452C4.35887 9.89915 4.44668 9.41866 4.62231 9.01058C4.79794 8.58933 5.00493 8.2339 5.24328 7.94429C5.49418 7.65468 5.82661 7.31899 6.24059 6.93724C6.80511 6.41067 7.21909 5.94993 7.48253 5.55501C7.75851 5.14692 7.89651 4.63352 7.89651 4.01481C7.89651 3.23813 7.60798 2.60625 7.03091 2.11918C6.45385 1.63211 5.64471 1.38858 4.60349 1.38858C3.76299 1.38858 3.03539 1.5268 2.4207 1.80324C1.806 2.06653 1.27285 2.47461 0.821237 3.0275L0.125 2.45487C1.16622 1.15162 2.67787 0.5 4.65995 0.5C5.93952 0.5 6.96192 0.809356 7.72715 1.42807C8.49238 2.03362 8.875 2.84979 8.875 3.87659C8.875 4.42948 8.78719 4.91655 8.61156 5.3378C8.44848 5.74589 8.24149 6.10132 7.99059 6.40409C7.75224 6.6937 7.42608 7.02938 7.0121 7.41114C6.62321 7.77974 6.32213 8.08909 6.10887 8.33921C5.89561 8.58933 5.71371 8.8921 5.56317 9.24753C5.41263 9.5898 5.33737 9.9913 5.33737 10.452H4.35887ZM4.84812 14.5C4.63486 14.5 4.45296 14.421 4.30242 14.263C4.15188 14.1051 4.07661 13.9142 4.07661 13.6904C4.07661 13.4666 4.15188 13.2757 4.30242 13.1178C4.45296 12.9598 4.63486 12.8808 4.84812 12.8808C5.07392 12.8808 5.2621 12.9598 5.41263 13.1178C5.56317 13.2757 5.63844 13.4666 5.63844 13.6904C5.63844 13.9142 5.56317 14.1051 5.41263 14.263C5.2621 14.421 5.07392 14.5 4.84812 14.5Z'
    fill='white'/>
</svg>;

export const NoModelSvg: React.FC = () =>
  <svg width='152' height='152' viewBox='0 0 152 152' fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M137.8 136.8V115H151V140.6C151 143.261 149.918 145.676 147.797 147.797C145.676 149.918 143.261 151 140.6 151H115V137.8H136.8H137.8V136.8ZM15.2 137.8H37V151H11.4C8.73872 151 6.32387 149.918 4.20311 147.797C2.08235 145.676 1 143.261 1 140.6V115H14.2V136.8V137.8H15.2ZM136.8 14.2H115V1H140.6C143.261 1 145.676 2.08235 147.797 4.20311C149.918 6.32387 151 8.73872 151 11.4V37H137.8V15.2V14.2H136.8ZM14.2 15.2V37H1V11.4C1 8.73872 2.08235 6.32387 4.20311 4.20311C6.32387 2.08235 8.73872 1 11.4 1H37V14.2H15.2H14.2V15.2ZM82.6 115.9V117.629L84.0985 116.767L114.498 99.2869L115 98.9985V98.42V63.536V61.8075L113.502 62.6691L83.1015 80.1491L82.6 80.4375V81.016V115.9ZM75.5032 68.6598L76 68.9443L76.4968 68.6598L106.897 51.2558L108.393 50.3992L106.907 49.5258L76.5065 31.6658L76 31.3682L75.4935 31.6658L45.0934 49.5258L43.6068 50.3992L45.1032 51.2558L75.5032 68.6598ZM37 98.42V98.9985L37.5015 99.2869L67.9015 116.767L69.4 117.629V115.9V81.016V80.4375L68.8985 80.1491L38.4985 62.6691L37 61.8075V63.536V98.42ZM81.1981 133.883L81.1793 133.894L81.1609 133.906C79.3913 135.038 77.6756 135.572 76 135.572C74.3244 135.572 72.6087 135.038 70.8391 133.906L70.8207 133.894L70.8019 133.883L29.173 109.726C25.6252 107.449 23.8 104.48 23.8 100.548V52.516C23.8 48.5838 25.6252 45.6145 29.173 43.3379L70.7816 19.1927C72.5386 18.2828 74.2743 17.872 76 17.872C77.7257 17.872 79.4614 18.2828 81.2184 19.1927L122.827 43.3379C126.375 45.6145 128.2 48.5838 128.2 52.516V100.548C128.2 104.48 126.375 107.449 122.827 109.726L81.1981 133.883Z'
      fill='#3587AA' stroke='white' strokeWidth='2'/>
  </svg>;

export const CrossSvg: React.FC = () =>
  <svg width='37' height='37' viewBox='0 0 37 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9.19238 9.19238L27.5772 27.5772' stroke='#3399B2' strokeWidth='2'/>
    <path d='M9.19238 27.5771L27.5772 9.19237' stroke='#3399B2' strokeWidth='2'/>
  </svg>;

export const AwaitSvg: React.FC = () =>
  <svg width='92' height='92' viewBox='0 0 92 92' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4' cy='46' r='4' fill='#3587AA'/>
    <circle cx='7' cy='30' r='4' fill='#3587AA'/>
    <circle cx='16' cy='16' r='4' fill='#3587AA'/>
    <circle cx='30' cy='7' r='4' fill='#3587AA'/>
    <circle cx='46' cy='4' r='4' fill='#3587AA'/>
    <circle opacity='0.05' cx='62' cy='7' r='4' fill='#3587AA'/>
    <circle opacity='0.1' cx='76' cy='16' r='4' fill='#3587AA'/>
    <circle opacity='0.2' cx='85' cy='30' r='4' fill='#3587AA'/>
    <circle r='4' transform='matrix(1 0 0 -1 7 62)' fill='#3587AA'/>
    <circle opacity='0.9' r='4' transform='matrix(1 0 0 -1 16 76)' fill='#3587AA'/>
    <circle opacity='0.8' r='4' transform='matrix(1 0 0 -1 30 85)' fill='#3587AA'/>
    <circle opacity='0.7' r='4' transform='matrix(1 0 0 -1 46 88)' fill='#3587AA'/>
    <circle opacity='0.6' r='4' transform='matrix(1 0 0 -1 62 85)' fill='#3587AA'/>
    <circle opacity='0.5' r='4' transform='matrix(1 0 0 -1 76 76)' fill='#3587AA'/>
    <circle opacity='0.4' r='4' transform='matrix(1 0 0 -1 85 62)' fill='#3587AA'/>
    <circle opacity='0.3' cx='88' cy='46' r='4' fill='#3587AA'/>
  </svg>;

export const SaveSvg: React.FC = () =>
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.7739 4.72873L19.2145 0.163069C19.118 0.0664531 18.9552 0 18.7923 0H17.0343H6.90236H1.59518C0.714678 0 0 0.718651 0 1.59739V22.4026C0 23.2843 0.717659 24 1.59518 24H4.98153H19.4406H22.4048C23.2853 24 24 23.2813 24 22.4026V5.15451C23.9668 5.02467 23.9035 4.8616 23.7739 4.72873ZM16.4463 1.20786V6.5556C16.4463 6.58883 16.4131 6.58883 16.4131 6.58883H14.7516V1.20786H16.4463ZM13.5153 1.20786V6.58883H7.5537C7.52052 6.58883 7.52052 6.5556 7.52052 6.5556V1.20786H13.5153ZM5.56654 22.7982V15.3276C5.56654 13.9265 6.70637 12.7851 8.10557 12.7851H16.2805C17.6796 12.7851 18.8195 13.9265 18.8195 15.3276V22.7951H5.56654V22.7982ZM22.7637 22.4056C22.7637 22.6019 22.6008 22.7982 22.3717 22.7982H20.0257V15.3276C20.0257 13.2743 18.331 11.5772 16.2805 11.5772H8.13871C6.08822 11.5772 4.39353 13.2743 4.39353 15.3276V22.7951H1.59216C1.39618 22.7951 1.20015 22.6321 1.20015 22.4026V1.59739C1.20015 1.40113 1.363 1.20483 1.59216 1.20483H6.31436V6.55258C6.31436 7.23804 6.86922 7.79064 7.55071 7.79064H16.4101C17.0946 7.79064 17.6465 7.23502 17.6465 6.55258V1.20786H18.5571L22.7576 5.4142L22.7637 22.4056ZM16.576 19.241C16.576 19.5672 16.3166 19.8269 15.991 19.8269H8.53072C8.20503 19.8269 7.94571 19.5672 7.94571 19.241C7.94571 18.9149 8.20503 18.6552 8.53072 18.6552H15.9548C16.3136 18.6552 16.576 18.9149 16.576 19.241ZM16.576 16.1127C16.576 16.4388 16.3166 16.6985 15.991 16.6985H8.53072C8.20503 16.6985 7.94571 16.4388 7.94571 16.1127C7.94571 15.7866 8.20503 15.5269 8.53072 15.5269H15.9548C16.3136 15.4907 16.576 15.7534 16.576 16.1127Z'
      fill='#3587AA'/>
  </svg>;

export const TrueSvg: React.FC = () =>
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.0655 9.03471C17.0655 13.47 13.47 17.0655 9.03471 17.0655C4.59942 17.0655 1.00391 13.47 1.00391 9.03471C1.00391 4.59942 4.59942 1.00391 9.03471 1.00391C13.47 1.00391 17.0655 4.59942 17.0655 9.03471Z'
      fill='#F8FAF8'/>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 16.875C13.3492 16.875 16.875 13.3492 16.875 9C16.875 4.65076 13.3492 1.125 9 1.125C4.65076 1.125 1.125 4.65076 1.125 9C1.125 13.3492 4.65076 16.875 9 16.875Z'
      fill='#F8FAF8'/>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M9.03471 15.9183C12.8364 15.9183 15.9183 12.8364 15.9183 9.03471C15.9183 5.23303 12.8364 2.15116 9.03471 2.15116C5.23303 2.15116 2.15116 5.23303 2.15116 9.03471C2.15116 12.8364 5.23303 15.9183 9.03471 15.9183ZM9.03471 17.0655C13.47 17.0655 17.0655 13.47 17.0655 9.03471C17.0655 4.59942 13.47 1.00391 9.03471 1.00391C4.59942 1.00391 1.00391 4.59942 1.00391 9.03471C1.00391 13.47 4.59942 17.0655 9.03471 17.0655Z'
      fill='#06B780'/>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M12.1719 6.22125C12.3177 6.08003 12.5145 6.00056 12.7198 6C12.925 5.99945 13.1223 6.07786 13.2689 6.21829C13.4155 6.35872 13.4996 6.54988 13.5031 6.75044C13.5066 6.95101 13.4292 7.14486 13.2876 7.29008L9.12905 12.3696C9.05758 12.4448 8.97132 12.5052 8.87543 12.5471C8.77953 12.589 8.67598 12.6116 8.57095 12.6135C8.46593 12.6154 8.36159 12.5966 8.26417 12.5582C8.16676 12.5197 8.07827 12.4625 8.004 12.39L5.24867 9.69649C5.17191 9.6266 5.11034 9.54231 5.06764 9.44866C5.02493 9.35501 5.00197 9.25392 5.00012 9.15141C4.99827 9.0489 5.01757 8.94708 5.05686 8.85201C5.09616 8.75695 5.15464 8.67059 5.22883 8.5981C5.30302 8.5256 5.3914 8.46845 5.48868 8.43005C5.58596 8.39165 5.69016 8.3728 5.79507 8.3746C5.89997 8.37641 6.00343 8.39885 6.09926 8.44058C6.1951 8.48231 6.28136 8.54247 6.35288 8.61748L8.53423 10.748L12.1521 6.24364C12.1586 6.23576 12.1645 6.22828 12.1719 6.22125Z'
      fill='#06B780'/>
  </svg>;

export const FalseSvg: React.FC = () =>
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.0655 9.03471C17.0655 13.47 13.47 17.0655 9.03471 17.0655C4.59942 17.0655 1.00391 13.47 1.00391 9.03471C1.00391 4.59942 4.59942 1.00391 9.03471 1.00391C13.47 1.00391 17.0655 4.59942 17.0655 9.03471Z'
      fill='#F8FAF8'/>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 16.875C13.3492 16.875 16.875 13.3492 16.875 9C16.875 4.65076 13.3492 1.125 9 1.125C4.65076 1.125 1.125 4.65076 1.125 9C1.125 13.3492 4.65076 16.875 9 16.875Z'
      fill='#F8FAF8'/>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M9.03471 15.9183C12.8364 15.9183 15.9183 12.8364 15.9183 9.03471C15.9183 5.23303 12.8364 2.15116 9.03471 2.15116C5.23303 2.15116 2.15116 5.23303 2.15116 9.03471C2.15116 12.8364 5.23303 15.9183 9.03471 15.9183ZM9.03471 17.0655C13.47 17.0655 17.0655 13.47 17.0655 9.03471C17.0655 4.59942 13.47 1.00391 9.03471 1.00391C4.59942 1.00391 1.00391 4.59942 1.00391 9.03471C1.00391 13.47 4.59942 17.0655 9.03471 17.0655Z'
      fill='#F04847'/>
    <path
      d='M10.4266 9.42651L12.4266 7.42651C12.8645 6.98862 13.0361 6.69281 12.5982 6.25493C12.1603 5.81704 11.8645 5.98862 11.4266 6.42651L9.4266 8.42651L7.42659 6.42651C6.9887 5.98862 6.69289 5.81705 6.25501 6.25494C5.81712 6.69283 5.9887 6.98862 6.42659 7.42651L8.42659 9.42651L6.42635 11.4265C5.98846 11.8644 5.81713 12.1602 6.25502 12.5981C6.69291 13.036 6.98846 12.8644 7.42635 12.4265L9.4266 10.4265L11.4263 12.4265C11.8641 12.8644 12.1603 13.036 12.5982 12.5981C13.0361 12.1602 12.8642 11.8644 12.4263 11.4265L10.4266 9.42651Z'
      fill='#F04847'/>
  </svg>;

