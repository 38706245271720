import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {modelsExtensions, t} from 'shared';
import {projectConfig} from 'models';
import {verifyFileExtension} from 'was-js-utils';
import {UploadModelBlockView} from './upload-model-block.view';

export const UploadModelBlock: React.FC = observer(() => {
  const [showDragAndDrop, setShowDragAndDrop] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const uploadFileFn = (file: File | null | undefined): void => {
    if (file && verifyFileExtension(modelsExtensions, file)) {
      setShowLoading(true);
      setShowDragAndDrop(false);
      projectConfig.uploadModel(file)
        .finally(() => setShowLoading(false));
    }
  };

  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.hasOwnProperty('target') ? e.target?.files?.[0] : null;

    uploadFileFn(file);
  };

  const buttonClick = (): void => setShowDragAndDrop(true);


  const uploadBtnText = projectConfig.modelNodeUrl ? t('Change the model') : t('Upload a model');

  return (
    <UploadModelBlockView onChangeFn={onChangeFn} uploadFileFn={uploadFileFn} showDragAndDrop={showDragAndDrop}
      setShowDragAndDrop={setShowDragAndDrop} showLoading={showLoading} buttonClick={buttonClick}
      buttonClickText={uploadBtnText}/>
  );
});
