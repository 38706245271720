import {GlobalStyles} from 'shared';
import React from 'react';
import {EditorLayout} from 'layouts';
import {CanvasWrapper, LeftInterface, Preloader, RightInterface} from 'widgets';
import {usePreloader, useAuth, useCheckProjectId, useLanguage} from 'shared/hooks';

export const App: React.FC = () => {
  const startRendering = usePreloader();
  useLanguage();
  useAuth();
  useCheckProjectId();

  return (
    <div className='App'>
      {startRendering &&
        <EditorLayout leftColumn={<LeftInterface/>} mainContent={<CanvasWrapper/>} rightColumn={<RightInterface/>}/>}
      <Preloader/>
      <GlobalStyles/>
    </div>
  );
};
