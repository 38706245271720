import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {TModelViewer} from 'shared/types';
import {EditorController, projectConfig} from 'models';
import {CanvasWrapperView} from './canvas-wrapper.view';

export const CanvasWrapper: React.FC = observer(() => {
  const modelViewerRef = useRef<TModelViewer>(null);
  const [mvLoadingProgress, setMvLoadingProgress] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const editorController = new EditorController(modelViewerRef.current);
    modelViewerRef.current.onprogress = (event: any): void => {
      if (event?.detail?.totalProgress)
        setMvLoadingProgress(event.detail.totalProgress * 100);
    };

    projectConfig.injectEditorController(editorController);
  }, []);

  useEffect(() => {
    if (mvLoadingProgress === 100 && !loaded) {
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
  }, [mvLoadingProgress]);

  const modelSrc = projectConfig.modelNodeUrl;

  return (
    <CanvasWrapperView ref={modelViewerRef} noModel={!modelSrc} loadingProgress={mvLoadingProgress} loaded={loaded}/>
  );
});
