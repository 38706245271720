import React from 'react';
import styled, {css, FlattenSimpleInterpolation} from 'styled-components';
import {CreateSvg, SVG_NAMES} from 'shared';
import {TPhotoItem} from './upload-photos-lib.types';

const Wrapper = styled.div`
  width: 192px;
  max-height: 167px;
  overflow-y: auto;
`;

const PhotosGrid = styled.div`
  display: grid;
  max-height: 167px;
  gap: 10px;
  grid-template-columns: repeat(3, 49px);
  grid-auto-rows: 49px;
`;

type TPhotoProps = {
  selected?: boolean;
};

const Photo = styled.img<TPhotoProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 3px;
  outline-offset: -2px;

  ${({selected}): FlattenSimpleInterpolation | null => (selected ? css`outline: 2px solid var(--light-blue);` : null)}
`;

const UploadBlock = styled.label`
  width: 100%;
  height: 100%;
  background-color: var(--blue-grey);
  border-radius: 3px;
  display: grid;
  place-content: center;
  cursor: pointer;
`;


type Props = {
  photosArray: TPhotoItem[];
  selectedPhotoId: string | undefined | null;
  changeSelectedPhoto: (photo: string) => void;
  uploadFn: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const UploadPhotosLibView: React.FC<Props> = ({
  photosArray,
  selectedPhotoId,
  changeSelectedPhoto,
  uploadFn
}: Props) => {
  const photosJsx = photosArray.map((photo: TPhotoItem) => {
    return <Photo
      src={photo.iconSrc}
      key={photo.src}
      alt={photo.src}
      selected={selectedPhotoId === photo.src}
      onClick={(): void => changeSelectedPhoto(photo.src)}
    />;
  });

  return (
    <Wrapper>
      <PhotosGrid>
        <UploadBlock onClick={uploadFn}>
          <CreateSvg name={SVG_NAMES.UPLOAD_IMAGE}/>
        </UploadBlock>
        {photosJsx}
      </PhotosGrid>
    </Wrapper>
  );
};
