import {useLayoutEffect} from 'react';
import {setCookie} from 'was-js-utils';
import {DOMAIN, E_LANGUAGES} from '../constants';
import {getLanguage} from '../utils';

export const useLanguage = (deps: any[] = []): void => {
  useLayoutEffect(() => {
    const language = getLanguage();
    if (!language) {
      setCookie('language', E_LANGUAGES.EN,
        {domain: DOMAIN, expires: new Date( Date.now() + 26784000000)}
      );
    }
  }, deps);
};
