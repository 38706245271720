import React from 'react';
import {TPhotoItem} from './upload-photos-lib.types';
import {UploadPhotosLibView} from './upload-photos-lib.view';

type Props = {
  photosArray: TPhotoItem[];
  selectedPhotoId: string | undefined | null;
  changeSelectedPhoto: (photo: string) => void;
  uploadFn: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const UploadPhotosLib: React.FC<Props> = ({photosArray, selectedPhotoId, changeSelectedPhoto, uploadFn}: Props) => {
  return (
    <UploadPhotosLibView
      photosArray={photosArray}
      selectedPhotoId={selectedPhotoId}
      changeSelectedPhoto={changeSelectedPhoto}
      uploadFn={uploadFn}
    />
  );
};
