import React from 'react';
import styled from 'styled-components';
import {SliderOption} from 'shared';
import {INITIAL_VIEW_DEFAULTS} from './initial-view.constants';

const Wrapper = styled.div`
  display: grid;
  row-gap: 10px;
`;

type Props = {
  theta: number;
  phi: number;
  R: number;
  setTheta: (val: number) => void;
  setPhi: (val: number) => void;
  setR: (val: number) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const InitialViewView: React.FC<Props> = ({theta, phi, R, setTheta, setPhi, setR}: Props) => {
  return (
    <Wrapper>
      <SliderOption value={theta} changeValue={setTheta} min={INITIAL_VIEW_DEFAULTS.MIN_THETA}
        max={INITIAL_VIEW_DEFAULTS.MAX_THETA}
        step={INITIAL_VIEW_DEFAULTS.STEP} label={'θ'} units={'°'}></SliderOption>
      <SliderOption value={phi} changeValue={setPhi} min={INITIAL_VIEW_DEFAULTS.MIN_PHI}
        max={INITIAL_VIEW_DEFAULTS.MAX_PHI}
        step={INITIAL_VIEW_DEFAULTS.STEP} label={'φ'} units={'°'}></SliderOption>
      <SliderOption value={R} changeValue={setR} min={INITIAL_VIEW_DEFAULTS.MIN_R} max={INITIAL_VIEW_DEFAULTS.MAX_R}
        step={INITIAL_VIEW_DEFAULTS.STEP} label={'R'} units={'%'}></SliderOption>
    </Wrapper>
  );
};
