import {secureFetch, getProjectId} from 'was-js-utils';
import {TInfoProjectDto} from 'shared';
import {BaseApi} from '../base-api';

export class OtherApi extends BaseApi {
  constructor() {
    super();
  }

  getProjectInfo = (): Promise<TInfoProjectDto> => {
    const projectId = getProjectId();
    return secureFetch(
      `${this.endPoint}/api/v2/get_info_project_from_id/?project_id=${projectId}`)
      .then((res: Response): Promise<TInfoProjectDto> => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      });
  };
}
