import {Box, CircularProgress} from '@mui/material';
import React from 'react';

type Props = {
  progress: number;
};

/**
 * @return {React.FC<Props>}
 **/
export const CircleLoaderView: React.FC<Props> = ({
  progress
}: Props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: '#3587AA77'
        }}
        size={61}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant='determinate'
        sx={{
          color: '#3587AA',
          left: 0,
          position: 'absolute',
        }}
        size={61}
        thickness={4}
        value={progress}
      />
    </Box>
  );
};
