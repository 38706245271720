import {observer} from 'mobx-react-lite';
import React from 'react';
import {projectInfo} from 'models';
import {ProjectPreviewView} from './project-preview.view';

type Props = {
  closeWindowFn: () => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const ProjectPreview: React.FC<Props> = observer(({
  closeWindowFn,
}: Props) => {
  const qrSrc = projectInfo.qrCodeSrc;
  return (
    <ProjectPreviewView closeWindowFn={closeWindowFn} qrSrc={qrSrc}/>
  );
});
