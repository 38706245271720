import React from 'react';
import styled, {keyframes} from 'styled-components';
import {IMAGES} from 'shared';

const pulse = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  5% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  95% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: var(--grey);
  display: grid;
  place-content: center;
`;

const Icon = styled.img`
  width: 200px;
  animation: ${pulse} ease-in-out 2s infinite;
`;

export const PreloaderView: React.FC = () => {
  return (
    <Wrapper>
      <Icon src={IMAGES.LOGO} alt={'logo'}/>
    </Wrapper>
  );
};
