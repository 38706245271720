import React, {useMemo} from 'react';
import {ColumnContainer, ColumnHeader, E_OPTIONS_VARIANTS} from 'shared';
import styled from 'styled-components';
import {CameraOptions} from '../camera-options';
import {LightOptions} from '../light-options';
import {OptionsSwitcher} from '../options-switcher';
import {TSwitcherOption} from '../options-switcher/options-switcher.types';
import {optionsHeaders} from './left-interface.constants';

const OptionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

type Props = {
  currentOptionsId: E_OPTIONS_VARIANTS;
  switcherOptions: TSwitcherOption[];
  changeOption: (opt: E_OPTIONS_VARIANTS) => void;
};
/**
 * @return {React.FC<Props>}
 **/
export const LeftInterfaceView: React.FC<Props> = ({currentOptionsId, switcherOptions, changeOption}: Props) => {
  const contentJsx = useMemo(() => {
    switch (currentOptionsId) {
    case E_OPTIONS_VARIANTS.LIGHT:
      return <LightOptions/>;
    case E_OPTIONS_VARIANTS.CAMERA:
      return <CameraOptions/>;
    }
  }, [currentOptionsId]);

  return (
    <ColumnContainer>
      <ColumnHeader>{optionsHeaders()[currentOptionsId]}</ColumnHeader>
      {contentJsx}
      <OptionsWrapper>
        <OptionsSwitcher changeOption={changeOption} currentOptionId={currentOptionsId}
          options={switcherOptions}/>
      </OptionsWrapper>
    </ColumnContainer>
  );
};
