import React from 'react';
import {IMAGES, ModalWindow, t} from 'shared';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 27px 19px 35px 19px;
  display: grid;
  justify-content: center;
  justify-items: center;
  row-gap: 27px;
`;

const Header = styled.h1`
  margin: 0;
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  color: var(--blue);
  display: grid;
  justify-content: center;
`;

const ScanQrBlock = styled.span`
  display: grid;
  place-content: center;
  border-radius: 10px;
  width: 478px;
  height: 40px;
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  text-align: center;

  color: white;
  background-color: var(--blue);
`;

const ImageWrapper = styled.div`
  position: relative;
  display: grid;
  place-content: center;
  place-items: center;
  background-image: url(${IMAGES.QR_CODE_FRAME});
  width: 175px;
  height: 178px;
  background-size: contain;
`;

const Image = styled.img`
  width: calc(100% - 10px);
`;

type Props = {
  closeWindowFn: () => void;
  qrSrc: string;
};

export const ProjectPreviewView: React.FC<Props> = ({closeWindowFn, qrSrc}: Props) => {
  return (
    <ModalWindow closeModalFn={closeWindowFn}>
      <Wrapper>
        <Header>{t('Preview')}</Header>
        <ScanQrBlock>{t('Scan the QR code')}</ScanQrBlock>
        <ImageWrapper>
          <Image src={qrSrc} alt='qr'/>
        </ImageWrapper>
      </Wrapper>
    </ModalWindow>
  );
};
