import React from 'react';
import styled, {css, FlattenSimpleInterpolation} from 'styled-components';
import circle from './assets/slider.svg';

type TSwitchWrapperProps = {
  active: boolean;
};

const SwitchWrapper = styled.div<TSwitchWrapperProps>`
  width: 100%;
  height: 100%;
  border-radius: 1000px;

  display: grid;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  padding: 0 3px;

  ${({active}): FlattenSimpleInterpolation => css`
    ${active ? `
      background-color: var(--blue);
      justify-content: right;
    ` : `
      background-color: var(--grey);
      justify-content: left;
    `}
  `}

  transition: background-color 200ms;
`;

const Slider = styled.img`
  height: 80%;
  user-select: none;
`;

type Props = {
  changeState: () => void;
  state: boolean;
};

export const SwitchView: React.FC<Props> = ({changeState, state}) => {
  return (
    <SwitchWrapper onClick={changeState} active={state}>
      <Slider src={circle} alt={'circle'}/>
    </SwitchWrapper>
  );
};
